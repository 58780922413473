<!--
Shows the transits Delivered
Child of TransitDashboard
-->
<template>
  <div>
    <h4>
      Received Transit
      <info-tooltip>
        Shows DELIVERED Transit entries.
      </info-tooltip>
    </h4>
    <v-sheet
      style="max-height: 75vh"
      class="overflow-y-auto pr-2"
    >
      <received-transit
        v-for="transit in $store.getters['transitDashboardStore/getReceivedTransits']"
        :key="transit.id"
        :transit="transit"
        @click="editReceivedTransit(transit)"
      />
    </v-sheet>
    <edit-received-transit
      v-model="show"
    />
  </div>
</template>

<script>
import ReceivedTransit from '@/views/Logistics/Transit/components/ReceivedTransit'
import EditReceivedTransit from '@/views/Logistics/Transit/components/EditReceivedTransit'
export default {
  name: 'ReceivedTransits',
  components: { EditReceivedTransit, ReceivedTransit },
  data: () => ({
    show: false
  }),
  methods: {
    /**
     * Sets transit edit dialog selection
     * @param transit
     */
    editReceivedTransit (transit) {
      this.$store.commit('transitDashboardStore/editTransit/setTransit', transit)
      this.show = true
    }
  }
}
</script>
