var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("activator",[_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_vm._v(" Reference Editor ")])],{"on":function () { return _vm.dialog = !_vm.dialog; }}),_c('edit-dialog',{attrs:{"headline-text":"Reference Editor","show-decline":false,"accept-text":"Done"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.fetching && !this.references.length)?_c('v-skeleton-loader',{attrs:{"type":"table-tbody"}}):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.dataTableHeaders,"items":_vm.references,"hide-default-footer":"","disable-pagination":"","disable-sort":""},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref$1){
var invalid = ref$1.invalid;
return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"subtitle-1"},[_vm._v(" Add New Reference ")]),_c('v-card-text',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Reference Type","items":_vm.modelRefTypes,"error-messages":errors,"item-text":"name","item-value":"id"},model:{value:(_vm.ref_type),callback:function ($$v) {_vm.ref_type=$$v},expression:"ref_type"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('ValidationProvider',{attrs:{"rules":"required|max:55","type":"text"},scopedSlots:_vm._u([{key:"default",fn:function(ref$1){
var errors = ref$1.errors;
return [_c('v-text-field',{attrs:{"label":"Reference ID","error-messages":errors},model:{value:(_vm.ref),callback:function ($$v) {_vm.ref=$$v},expression:"ref"}})]}}],null,true)})],1),_c('v-col',{class:{ 'text-right': _vm.$vuetify.breakpoint.smAndDown },attrs:{"cols":"12","md":"2"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"disabled":invalid,"small":"","color":"primary","title":"Add new reference"},on:{"click":_vm.createReference}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)]}}],null,true)})],1)],1)],1)],1)],1)]}}])})]},proxy:true},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.confirmDelete(item.id)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}])}),_c('v-dialog',{attrs:{"max-width":"400px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Confirm Deleting Reference ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"red--text",attrs:{"text":""},on:{"click":_vm.deleteReference}},[_vm._v(" Delete ")])],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }