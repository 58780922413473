<template>
  <v-list>
    <request-tile
      :disabled="disabled"
      v-for="(rx, index) in requestList"
      :key="index"
      :request="rx"
      @click="$emit('click', $event)"
    >
      <template
        v-slot:default="{ request }"
      >
        <div
          class="d-flex"
        >
          <div
            class="justify-start"
          >
            <small>
              <transit-properties
                v-if="request.transit_properties"
                :properties="request.transit_properties"
              />
            </small>
          </div>
          <div
            class="ml-auto"
          >
            <small v-if="request.to_location">
              {{ request.to_location.name }}
            </small>
          </div>
        </div>
      </template>
    </request-tile>
  </v-list>
</template>

<script>
import RequestTile from '@/views/Logistics/Transit/components/RequestTile'
import TransitProperties from '@/views/Logistics/Transit/components/TransitProperties'
export default {
  name: 'RequestTileList',
  components: { TransitProperties, RequestTile },
  props: {
    requestList: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
