<template>
  <unauthorized
    :authorized="$store.getters.can('view-transit')"
    message="Not Authorized to view Trips"
  >
    <base-title title="Transit List" />
    <base-transit-list
      page-name-override="transit-list"
      :filters="{ transit_type: 'IN' }"
    />
    <fixed-text-fab
      :disabled="!$store.getters.can('create-transit')"
      icon-text="mdi-plus"
      @click="$router.push({ name: 'transit-create', query: { transit_type: 'IN'}})"
    >
      Create Transit
    </fixed-text-fab>
  </unauthorized>
</template>

<script>
import BaseTransitList from '@/views/Logistics/TransitList/BaseTransitList.vue'
import FixedTextFab from '@/components/Buttons/FixedTextFab.vue'

export default {
  name: 'InboundList',
  components: { FixedTextFab, BaseTransitList }
}
</script>
