var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('unauthorized',{attrs:{"authorized":_vm.$store.getters.can('view-lot-manifests'),"message":"Not Authorized to view Lot Manifests"}},[_c('base-title',{attrs:{"title":"Lot Manifest List"}}),_c('search-filters',{attrs:{"chips":_vm.chips},on:{"clearChip":_vm.clearChipProp,"clearFields":_vm.clearFields,"submitSearch":_vm.routePage},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('date-range-input',{attrs:{"start-date":_vm.createdStartDate,"end-date":_vm.createdEndDate,"start-date-label":"Creation Date From","end-date-label":"Creation Date To"},on:{"alterStartDate":function($event){_vm.createdStartDate = $event},"alterEndDate":function($event){_vm.createdEndDate = $event}}}),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.xs ? 12 : 6}},[_c('v-text-field',{attrs:{"label":"ID","type":"number"},model:{value:(_vm.id),callback:function ($$v) {_vm.id=$$v},expression:"id"}})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.xs ? 12 : 6}},[_c('v-text-field',{attrs:{"label":"Created By"},model:{value:(_vm.createdBy),callback:function ($$v) {_vm.createdBy=$$v},expression:"createdBy"}})],1)],1)],1),(_vm.pagination)?_c('result-pagination',{attrs:{"pages":Math.ceil(_vm.totalCount / 20)},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}}):_vm._e(),_c('v-data-table',{attrs:{"multi-sort":"","headers":_vm.headers,"items":_vm.resultList,"server-items-length":_vm.totalCount,"options":_vm.pagination,"footer-props":{ 'items-per-page-options': [20] },"hide-default-footer":""},on:{"update:options":function($event){_vm.pagination=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link-id',{attrs:{"id":item.id,"name":"lot-manifest"}})]}},{key:"item.lots__details",fn:function(ref){
var item = ref.item;
return _vm._l((item.lots__details),function(lot,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(lot.name)+" ")])})}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at))+" ")]}},{key:"item.transit_id",fn:function(ref){
var item = ref.item;
return [(item.transit_id)?_c('router-link-id',{attrs:{"id":item.transit_id,"name":"transit-view"}},[_vm._v(" "+_vm._s(item.transit_id)+" ")]):_vm._e()]}},{key:"item.transit_requested",fn:function(ref){
var item = ref.item;
return [(item.transit_requested)?_c('v-icon',{attrs:{"color":"success lighten-1"}},[_vm._v(" mdi-circle ")]):_vm._e()]}}])}),(_vm.pagination)?_c('result-pagination',{attrs:{"pages":Math.ceil(_vm.totalCount / 20)},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}}):_vm._e(),_c('fixed-text-fab',{attrs:{"disabled":!_vm.$store.getters.can('create-lot-manifests'),"icon-text":"mdi-plus"},on:{"click":function($event){return _vm.$router.push({ name: 'create-lot-manifest' })}}},[_vm._v(" Create Lot Manifest ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }