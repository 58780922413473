<template>
  <unauthorized
    :authorized="$store.getters.can('view-transit')"
    message="You are not authorized to view transit"
  >
    <div>
      <base-title title="Merge Transit" />
    </div>
    <v-expand-transition>
      <v-select
        v-if="!selected"
        label="Select a Transit to Merge Into"
        item-text="name"
        :items="this.$store.getters['transitDashboardStore/getBookedTransits']"
        return-object
        @change="x => selected = x"
      />
      <div
        v-if="selected"
      >
        <v-card
          flat
        >
          <booked-transit
            :transit="selected"
          />
          <v-card-actions>
            <v-spacer />
            <v-btn
              small
              color="accent"
              @click="clearSelected"
            >
              Reset Selected
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-expand-transition>
    <v-expand-transition>
      <div v-if="selected">
        <hr class="my-4 mx-8">
        <v-sheet
          elevation="2"
          class="pa-4"
        >
          <v-data-table
            v-model="selectedToMerge"
            :headers="headers"
            :items="selectable"
            show-select
          >
            <template v-slot:top>
              <div class="subtitle-1">
                Selected {{ selectedToMerge.length }}
              </div>
            </template>
            <template v-slot:item.attached="{ item }">
              <v-chip
                v-for="(att, index) in item.attached"
                :key="index"
                small
              >
                {{ att.name }}
              </v-chip>
            </template>
          </v-data-table>
        </v-sheet>
        <div class="mt-4 d-flex justify-end">
          <v-btn
            :disabled="!selectedToMerge.length"
            color="primary"
            small
            @click="mergeTransits"
          >
            Merge Transits
          </v-btn>
        </div>
      </div>
    </v-expand-transition>
  </unauthorized>
</template>

<script>

import BookedTransit from '@/views/Logistics/Transit/components/BookedTransit.vue'
import utils from '@/helpers/transitUtils'

export default {
  name: 'MergeTransitView',
  components: { BookedTransit },
  data: () => ({
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'From', value: 'from_address_details.name' },
      // { text: 'To', value: 'to_address_details.name' },
      { text: 'Attached', value: 'attached' }
    ],
    selectedToMerge: [],
    selected: null
  }),
  computed: {
    selectable () {
      if (!this.selected) return []
      let booked = this.$store.getters['transitDashboardStore/getBookedTransits']
      booked.map(transit => {
        transit.attached = utils.requestList(transit)
      })
      booked = booked.filter(x => {
        if (x.id === this.selected.id) return false
        if (x.to_address_details.id !== this.selected.to_address_details.id) return false
        for (const attached of x.attached) {
          if (attached.type !== 'CHECKIN') return false
        }
        return true
      })
      return booked
    }
  },
  methods: {
    clearSelected () {
      this.selected = null
      this.selectedToMerge = []
    },
    fetchTransits () {
      this.$store.dispatch('transitDashboardStore/fetchTransits')
    },
    mergeTransits () {
      if (confirm('Merge transits?')) {
        const targetTransitId = this.selected.id
        const transitIds = this.selectedToMerge.map(x => x.id)
        this.$store.dispatch('transitDashboardStore/mergeTransits', { targetTransitId, transitIds }).then(res => {
          if (res.status === 200) {
            this.$store.commit('setSnackbarSuccess', res.data.message)
            this.clearSelected()
            this.fetchTransits()
          } else {
            this.$store.commit('setSnackbarError', res.data.message)
          }
        })
      }
    }
  },
  mounted () {
    this.fetchTransits()
  }
}
</script>
