<template>
  <unauthorized
    :authorized="$store.getters.can('view-carrier')"
    message="Unauthorized to view Carrier"
  >
    <div v-if="entry">
      <url-breadcrumbs
        :items="[
          ['Carrier List', { name: 'carrier-list' }],
          ['Carrier #' + entry.id, { name: 'carrier-view', params: { id: entry.id }}]
        ]"
      />
      <v-container fluid>
        <base-title>
          {{ entry.name || '--' }}
          <v-chip
            small
            color="primary"
          >
            {{ entry.status_text.toUpperCase() }}
          </v-chip>
          <template v-slot:subtitle>
            Carrier #{{ entry.id }}
          </template>
        </base-title>
        <v-toolbar
          flat
          dense
        >
          <v-spacer />
          <v-btn
            :disabled="!$store.getters.can('edit-carrier')"
            small
            color="primary"
            @click="editDialog = !editDialog"
          >
            Edit
          </v-btn>
        </v-toolbar>
        <v-row>
          <v-col>
            <v-card>
              <v-card-text>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Website
                      </v-list-item-title>
                      <a :href="entry.website">{{ entry.website }}</a>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Contact Name
                      </v-list-item-title>
                      {{ entry.contact_name }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Phone
                      </v-list-item-title>
                      <a :href="'tel: ' + entry.phone_number">{{ entry.phone_number }}</a>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Email
                      </v-list-item-title>
                      <a :href="'mailto: ' + entry.email">{{ entry.email }}</a>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <p class="font-italic red--text text-center">
                  These fields are not show in the Transit Dashboard.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <ValidationObserver
        v-slot="{ invalid }"
      >
        <edit-dialog
          v-model="editDialog"
          headline-text="Edit Carrier"
          :inputs-valid="!invalid"
          @accept="updateCarrier"
        >
          <v-card-text>
            <ValidationProvider
              rules="required|max:32"
              v-slot="{ errors }"
            >
              <v-text-field
                label="Name"
                v-model="name"
                :error-messages="errors"
              />
            </ValidationProvider>
            <ValidationProvider
              rules="max:40"
              v-slot="{ errors }"
            >
              <v-text-field
                label="Contact Name"
                v-model="contactName"
                :error-messages="errors"
              />
            </ValidationProvider>
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
            >
              <v-select
                label="Status"
                v-model="status"
                :items="$store.getters['getBusinessStatus']"
                :error-messages="errors"
              />
            </ValidationProvider>
            <ValidationProvider
              rules="url"
              v-slot="{ errors }"
            >
              <v-text-field
                label="Website"
                v-model="website"
                :error-messages="errors"
              />
            </ValidationProvider>
            <ValidationProvider
              rules="max:40"
              v-slot="{ errors }"
            >
              <v-text-field
                label="Phone Number"
                v-model="phoneNumber"
                :error-messages="errors"
              />
            </ValidationProvider>
            <ValidationProvider
              rules="email"
              v-slot="{ errors }"
            >
              <v-text-field
                label="Email"
                v-model="email"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-card-text>
        </edit-dialog>
      </ValidationObserver>
    </div>
  </unauthorized>
</template>

<script>
export default {
  name: 'CarrierView',
  data: () => ({
    editDialog: false,
    name: '',
    status: null,
    website: '',
    phoneNumber: '',
    contactName: '',
    email: ''
  }),
  watch: {
    editDialog (opened) {
      if (!opened) return
      this.name = this.entry.name
      this.status = this.entry.status
      this.website = this.entry.website
      this.phoneNumber = this.entry.phone_number
      this.contactName = this.entry.contact_name
      this.email = this.entry.email
    }
  },
  computed: {
    entry () {
      return this.$store.state['carrierStore'].items[this.$route.params.id]
    }
  },
  methods: {
    updateCarrier () {
      const payload = {
        name: this.name,
        status: this.status,
        website: this.website,
        phone_number: this.phoneNumber,
        contact_name: this.contactName,
        email: this.email
      }
      this.$store.dispatch('carrierStore/patchCarrier', { id: this.$route.params.id, payload })
    }
  },
  mounted () {
    this.$store.dispatch('carrierStore/fetchCarrier', this.$route.params.id)
  }
}
</script>
