<template>
  <div>
    <slot
      name="activator"
      :on="addBoxDialog"
    >
      <v-btn
        color="primary"
        small
        @click="addBoxDialog"
      >
        Add Boxes
      </v-btn>
    </slot>
    <edit-dialog
      v-model="dialog"
      headline-text="Add New Boxes"
      :inputs-valid="howMany > 0"
      @accept="createBoxes"
      accept-text="Add Boxes"
    >
      <v-text-field
        label="How Many Boxes"
        type="number"
        value="howMany"
        v-model="howMany"
        hint="enter how many boxes you would like to add"
        append-icon="mdi-plus-box"
        @click:append="howMany = howMany + 1"
      />
      <v-select
        label="Check In Select (optional)"
        v-model="checkInSelected"
        :items="checkIns"
        return-object
        multiple
        clearable
        small-chips
        deletable-chips
        item-text="name"
      />
      <p class="font-weight-bold text-center">
        Select the check ins that are included in these boxes. (all selected check ins will be attached to all created boxes)
      </p>
    </edit-dialog>
  </div>
</template>

<script>
export default {
  name: 'AddBoxDialog',
  data: () => ({
    dialog: false,
    howMany: 1,
    checkInSelected: []
  }),
  computed: {
    checkIns () {
      return this.$store.getters['transitDashboardStore/editTransit/transit'].check_ins
    }
  },
  methods: {
    /**
     * Initializes the components properties
     */
    addBoxDialog () {
      this.howMany = 1
      this.checkInSelected = this.checkIns
      this.dialog = true
    },
    /**
     * Emits addBox with the skids and all related check ins
     * @param checkIns
     * @param skids
     */
    createBoxes () {
      const payload = {
        batch: this.howMany,
        check_ins: this.checkInSelected.map(ci => ci.id).join(',')
      }
      this.$store.dispatch('transitDashboardStore/editTransit/createBoxes', payload)
    }
  }
}
</script>
