var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('unauthorized',{attrs:{"authorized":_vm.$store.getters.can('view-carrier'),"message":"Unauthorized to view Carrier"}},[(_vm.entry)?_c('div',[_c('url-breadcrumbs',{attrs:{"items":[
        ['Carrier List', { name: 'carrier-list' }],
        ['Carrier #' + _vm.entry.id, { name: 'carrier-view', params: { id: _vm.entry.id }}]
      ]}}),_c('v-container',{attrs:{"fluid":""}},[_c('base-title',{scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_vm._v(" Carrier #"+_vm._s(_vm.entry.id)+" ")]},proxy:true}],null,false,4007446780)},[_vm._v(" "+_vm._s(_vm.entry.name || '--')+" "),_c('v-chip',{attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.entry.status_text.toUpperCase())+" ")])],1),_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":!_vm.$store.getters.can('edit-carrier'),"small":"","color":"primary"},on:{"click":function($event){_vm.editDialog = !_vm.editDialog}}},[_vm._v(" Edit ")])],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-text',[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Website ")]),_c('a',{attrs:{"href":_vm.entry.website}},[_vm._v(_vm._s(_vm.entry.website))])],1)],1)],1),_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Contact Name ")]),_vm._v(" "+_vm._s(_vm.entry.contact_name)+" ")],1)],1)],1),_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Phone ")]),_c('a',{attrs:{"href":'tel: ' + _vm.entry.phone_number}},[_vm._v(_vm._s(_vm.entry.phone_number))])],1)],1)],1),_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Email ")]),_c('a',{attrs:{"href":'mailto: ' + _vm.entry.email}},[_vm._v(_vm._s(_vm.entry.email))])],1)],1)],1),_c('p',{staticClass:"font-italic red--text text-center"},[_vm._v(" These fields are not show in the Transit Dashboard. ")])],1)],1)],1)],1)],1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var invalid = ref.invalid;
return [_c('edit-dialog',{attrs:{"headline-text":"Edit Carrier","inputs-valid":!invalid},on:{"accept":_vm.updateCarrier},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('v-card-text',[_c('ValidationProvider',{attrs:{"rules":"required|max:32"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Name","error-messages":errors},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"max:40"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Contact Name","error-messages":errors},model:{value:(_vm.contactName),callback:function ($$v) {_vm.contactName=$$v},expression:"contactName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Status","items":_vm.$store.getters['getBusinessStatus'],"error-messages":errors},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Website","error-messages":errors},model:{value:(_vm.website),callback:function ($$v) {_vm.website=$$v},expression:"website"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"max:40"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Phone Number","error-messages":errors},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email","error-messages":errors},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1)],1)]}}],null,false,2015893468)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }