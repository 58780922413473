<template>
  <div v-if="this.transit">
    <h3>Boxes</h3>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <h4>
              {{ attachedNames().join(' / ') }}
            </h4>
            Expected Skids: <strong>{{ transitProperties.skids }}</strong>
            Weight: <strong>{{ transitProperties.weight }} lbs</strong>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!readonly">
      <v-col>
        <v-card>
          <v-card-title>
            Step 1: Enter Total Box Count
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <ValidationProvider
                  rules="required|min_value:1"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    type="number"
                    label="Box Count*"
                    v-model="boxCount"
                    hint="Total number of boxes arriving in transit."
                    :rules="[x => !!x || 'This field is required']"
                    :error-messages="errors"
                  />
                  <div
                    v-if="rowsAndCountMatch"
                    class="red--text text--darken-3 v-size--x-small"
                  >
                    <small>
                      The number of boxes and this count do not match.
                    </small>
                  </div>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <span v-if="!readonly">Step 2: </span>
            Inventory Boxes
            <v-spacer />
          </v-card-title>
          <ul v-if="!readonly">
            <li>
              Boxes are automatically imported when loaded from Manifest and Check Ins (If they do not exist).
              <ul>
                <li>
                  Only imports declared skids from the check in. (not estimated)
                </li>
              </ul>
            </li>
            <li>
              The number of boxes must match the declared box count.
            </li>
            <li>
              Boxes may be removed form this table using the <v-icon small>
                mdi-dots-vertical
              </v-icon> icon.
            </li>
            <li>
              Boxes may be combined by first removing them from the table. Then selecting skids on the <v-btn x-small>
                Add Box
              </v-btn> dialog.
            </li>
            <li>
              All Skids (created at check in) must be accounted for. Do this by adding boxes with the attached skids.
            </li>
          </ul>
          <v-card-text>
            <v-data-table
              :headers="headers()"
              :items="boxRows"
              :items-per-page="-1"
            >
              <template v-slot:item.index="{ item }">
                {{ item.index + 1 }}
              </template>
              <template v-slot:item.gpsNumber="{ item }">
                {{ item.gpsNumber || '--' }}
              </template>
              <template
                v-if="!readonly"
                v-slot:item.weight="{ item }"
              >
                <ValidationProvider
                  rules="required|min_value:1"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    style="max-width: 200px"
                    label="Received lbs"
                    type="number"
                    v-model="item.weight"
                    :rules="errors"
                    @change="updateWeight(item, $event)"
                  />
                </ValidationProvider>
              </template>
              <template
                v-slot:item.menu="{ item }"
              >
                <div class="text-right">
                  <v-menu
                    bottom
                    left
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>
                          mdi-dots-vertical
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item
                        @click="setMergeBox(item)"
                      >
                        Combine Boxes...
                      </v-list-item>
                      <v-list-item
                        @click="removeBox(item)"
                      >
                        Remove Box
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </template>
              <template v-slot:body.append>
                <tr v-if="!$vuetify.breakpoint.xsOnly">
                  <td />
                  <td>Total Weight</td>
                  <td />
                  <td>
                    {{ totalReceivedWeight | formatNumber }} lbs
                  </td>
                  <td v-if="!readonly" />
                </tr>
                <div v-else>
                  Total Weight {{ totalReceivedWeight | formatNumber }} lbs
                </div>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <add-box-dialog
              v-if="!readonly"
            />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <box-row-select
      v-if="boxMergeDestination"
      :headline-text="'Merge Boxes into ' + boxMergeDestination.name"
      v-model="selectBoxDialog"
      :boxes="boxRows.filter(x => boxMergeDestination ? x.index !== boxMergeDestination.index : true)"
      @boxesSelected="combineBoxes"
    />
  </div>
  <div v-else>
    <h3>Boxes</h3>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <v-skeleton-loader
          v-bind="attrs"
          type="heading, heading"
        />
        <v-skeleton-loader
          v-bind="attrs"
          type="table-heading, table-thead, table-row-divider@3, table-tfoot"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AddBoxDialog from '@/views/Logistics/Transit/AddBoxDialog'
import BoxRowSelect from '@/views/Logistics/Transit/components/BoxRowSelect'

export default {
  name: 'ReceivedBoxes',
  components: { BoxRowSelect, AddBoxDialog },
  props: {
    // Represents IS VALID
    value: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    boxMergeDestination: null,
    selectBoxDialog: false,
    boxCount: 0,
    attrs: {
      class: 'mb-6',
      boilerplate: true,
      elevation: 2
    }
  }),
  computed: {
    rowsAndCountMatch () {
      return this.boxRows.length === Number(this.boxCount)
    },
    ...mapGetters('transitDashboardStore/editTransit', [
      'totalReceivedWeight',
      'transit',
      'boxRows',
      'boxes',
      'transitProperties'
    ])
  },
  watch: {
    rowsAndCountMatch: {
      handler (value) {
        this.$emit('input', value)
      },
      immediate: true
    }
  },
  methods: {
    // Transit Box list headers
    headers () {
      let h = [
        { text: '', value: 'index' },
        { text: 'Ident', value: 'name' },
        { text: 'Checkin IDs', value: 'checkinIds' },
        { text: 'Yards', value: 'yardNames' },
        { text: 'Weight', value: 'weight' },
        { text: 'GPS', value: 'gpsNumber' }
      ]
      if (!this.readonly) h.push({ text: '', value: 'menu' })
      return h
    },
    // Gets the text that should be shown for each box
    boxText (x) {
      const t = [x.index]
      if (x.name) t.push(x.name)
      t.push(x.weight + ' lbs')
      return t.join(' ')
    },
    /**
     * Opens the merge box dialog and sets the merge destination
     */
    setMergeBox (box) {
      this.boxMergeDestination = box
      this.selectBoxDialog = true
    },
    /**
     * Executes the Merge transit boxes command
     * @param selected
     */
    combineBoxes (selected) {
      this.$store.dispatch('transitDashboardStore/editTransit/mergeBoxes', {
        destinationBox: this.boxMergeDestination.meta,
        boxes: selected.map(box => box.meta)
      })
    },
    // Gets the manifest and check in names
    attachedNames () {
      return [
        this.transit.check_ins.map(x => x.name),
        this.transit.manifests.map(x => x.name)
      ].flat(1)
    },
    /**
     * Updates the weight of a transit box
     * @param box
     * @param weight
     */
    updateWeight (box, weight) {
      if (weight === '') weight = 0
      this.$store.dispatch('transitDashboardStore/editTransit/updateBox', { box, weight })
    },
    // Removes a box from boxes based on the index
    removeBox (box) {
      if (box.weight > 0) {
        this.$store.commit('setSnackbarError', 'box weight must be zero before deletions')
        return
      }
      if (confirm('Are you sure you would like to remove this box?')) {
        this.$store.dispatch('transitDashboardStore/editTransit/deleteBox', box.meta)
      }
    }
  }
}
</script>
