<template>
  <div>
    <search-filters
      v-model="search"
      :chips="chips"
      @clearChip="clearChipProp"
      @clearFields="clearFields"
      @submitSearch="routePage"
      @advancedOpened="fetchSearchDependencies"
    >
      <date-range-input
        :start-date="pickUpStartDate"
        :end-date="pickUpEndDate"
        start-date-label="Pick Up Start Date"
        end-date-label="Pick Up End Date"
        @alterStartDate="pickUpStartDate = $event"
        @alterEndDate="pickUpEndDate = $event"
      />
      <date-range-input
        :start-date="deliveryStartDate"
        :end-date="deliveryEndDate"
        start-date-label="Delivery Start Date"
        end-date-label="Delivery End Date"
        @alterStartDate="deliveryStartDate = $event"
        @alterEndDate="deliveryEndDate = $event"
      />
      <date-range-input
        :start-date="bookedStartDate"
        :end-date="bookedEndDate"
        start-date-label="Booked Start Date"
        end-date-label="Booked End Date"
        @alterStartDate="bookedStartDate = $event"
        @alterEndDate="bookedEndDate = $event"
      />
      <date-range-input
        :start-date="receivedStartDate"
        :end-date="receivedEndDate"
        start-date-label="Received Start Date"
        end-date-label="Received End Date"
        @alterStartDate="receivedStartDate = $event"
        @alterEndDate="receivedEndDate = $event"
      />
      <v-row>
        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">
          <v-text-field
            label="ID"
            v-model="id"
            type="number"
          />
        </v-col>
        <v-col
          :cols="$vuetify.breakpoint.xs ? 12 : 6"
        >
          <v-autocomplete
            label="Status"
            v-model="status"
            clearable
            :items="$store.getters['getTransitStatuses']"
            item-text="text"
            item-value="value"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">
          <v-text-field
            label="Checkin ID"
            v-model="checkInId"
            type="number"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            label="From Location"
            v-model="fromAddress"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            label="To Location"
            v-model="toAddress"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            label="Broker"
            v-model="broker"
            :items="brokers"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            label="Carrier"
            v-model="carrier"
            :items="carriers"
          />
        </v-col>
      </v-row>
    </search-filters>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />
    <v-data-table
      multi-sort
      :headers="headers"
      :items="resultList"
      :server-items-length="totalCount"
      :options.sync="pagination"
      :footer-props="{ 'items-per-page-options': [20] }"
      @page-count="pageCount = $event"
      hide-default-footer
    >
      <template v-slot:item.id="{ item }">
        <router-link-id
          :id="item.id"
          name="transit-view"
        />
      </template>
      <template v-slot:item.from_address="{ item }">
        <span v-if="item.from_address_details">
          {{ item.from_address_details.name }}
        </span>
      </template>
      <template v-slot:item.to_address="{ item }">
        <span v-if="item.to_address_details">
          {{ item.to_address_details.name }}
        </span>
      </template>
      <template v-slot:item.broker="{ item }">
        {{ brokerText(item) }}
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip
          small
          :color="item.color"
        >
          {{ item.status_display }}
        </v-chip>
      </template>
      <template v-slot:item.booked_by="{ item }">
        {{ item.booked_by__username }}
      </template>
      <template v-slot:item.pickup_date="{ item }">
        {{ item.pickup_date | formatDate }}
      </template>
      <template v-slot:item.delivery_date="{ item }">
        {{ item.delivery_date | formatDate }}
      </template>
    </v-data-table>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />
  </div>
</template>

<script>
import axios from 'axios'
import chipsMixin from '@/base/filter/chipsMixin'
import paginationMixin from '@/base/filter/paginationMixin'
import SearchFilters from '@/base/filter/SearchFilters.vue'
import ResultPagination from '@/base/components/ResultPagination.vue'

export default {
  components: { ResultPagination, SearchFilters },
  mixins: [chipsMixin, paginationMixin],
  name: 'BaseTransitList',
  props: {
    filters: {
      type: Object,
      required: true
    },
    pageNameOverride: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      headers: [
        { text: 'Id', sortable: true, value: 'id' },
        { text: 'From', sortable: true, value: 'from_address' },
        { text: 'To', sortable: true, value: 'to_address' },
        { text: 'Status', sortable: true, value: 'status' },
        { text: 'Broker/Carrier', sortable: true, value: 'broker' },
        { text: 'Pickup Date', sortable: true, value: 'pickup_date' },
        { text: 'Delivery Date', sortable: true, value: 'delivery_date' },
        { text: 'Booked By', sortable: true, value: 'booked_by' }
      ],

      ...this.chipPropagation([
        { name: 'ID', prop: 'id', type: String, query: 'id' },
        { name: 'Check In', prop: 'checkInId', type: String, query: 'checkin__id' },
        { name: 'From', prop: 'fromAddress', type: String, query: 'from_address__name__icontains' },
        { name: 'To', prop: 'toAddress', type: String, query: 'to_address__name__icontains' },
        { name: 'Broker', prop: 'broker', type: String, query: 'broker__name' },
        { name: 'Carrier', prop: 'carrier', type: String, query: 'carrier__name' },
        { name: 'Status', prop: 'status', type: String, query: 'status' },

        { name: 'Pick Up Start Date', prop: 'pickUpStartDate', type: String, query: 'pickup_date__gte' },
        { name: 'Pick Up End Date', prop: 'pickUpEndDate', type: String, query: 'pickup_date__lte' },
        { name: 'Delivery Start Date', prop: 'deliveryStartDate', type: String, query: 'delivery_date__gte' },
        { name: 'Delivery End Date', prop: 'deliveryEndDate', type: String, query: 'delivery_date__lte' },
        { name: 'Booked Start Date', prop: 'bookedStartDate', type: String, query: 'booked_at__gte' },
        { name: 'Booked End Date', prop: 'bookedEndDate', type: String, query: 'booked_at__lte' },
        { name: 'Received Start Date', prop: 'receivedStartDate', type: String, query: 'received_at__gte' },
        { name: 'Received End Date', prop: 'receivedEndDate', type: String, query: 'received_at__lte' }
      ]),

      brokers: [],
      carriers: []
    }
  },
  watch: {
    // observes and updated options
    pagination: function (updated) {
      this.routePage(updated)
    }
  },
  methods: {
    fetchSearchDependencies () {
      this.fetchBrokers()
      this.fetchCarriers()
    },
    fetchBrokers () {
      if (this.brokers.length === 0) {
        axios.get('/logistics/brokers/?page_size=0&fields=name').then(response => {
          this.brokers = response.data.map(d => d.name)
        })
      }
    },
    fetchCarriers () {
      if (this.carriers.length === 0) {
        axios.get('/logistics/carriers/?page_size=0&fields=name').then(response => {
          this.carriers = response.data.map(d => d.name)
        })
      }
    },
    brokerText (transit) {
      let x = []
      if (transit.broker_data) x.push(transit.broker_data.name)
      if (transit.carrier_data) x.push(transit.carrier_data.name)
      return x.join(' / ')
    },
    async executeSearch () {
      const params = Object.assign(this.getQueryParams(), this.filters)
      params['fields'] = [
        'url',
        'status',
        'status_display',
        'color',
        'broker_data',
        'broker',
        'carrier_data',
        'carrier',
        'from_address',
        'from_address_details',
        'to_address',
        'to_address_details',
        'pickup_date',
        'delivery_date',
        'rate_quote',
        'actual_cost',
        'booked_by__username',
        'booked_by',
        'booked_at',
        'received_at',
        'id'
      ].join(',')
      axios.get('/logistics/transits/', { params }).then(this.setResponseResults)
    }
  },
  mounted () {
    this.reflectRouteParams()
    this.executeSearch()
    this.pageName = this.pageNameOverride
  }
}
</script>
