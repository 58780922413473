<template>
  <ValidationObserver
    v-slot="{ invalid }"
  >
    <edit-dialog
      :value="value"
      headline-text="Edit Transit"
      :inputs-valid="!invalid"
      @accept="saveChanges"
      @input="$emit('input', $event)"
    >
      <v-card-text>
        <v-select
          v-model="status"
          :items="availableStatuses"
          label="Status"
        />
        <v-alert
          v-if="sendInTransitEmail"
          dense
          outlined
          color="primary"
        >
          <strong>In Transit</strong> email will be sent
        </v-alert>
        <v-switch
          :disabled="!deliveredStatuses.includes(status)"
          label="Send Outbound Delivered Notification"
          v-model="sendDeliveredEmail"
        />
        <v-select
          label="Broker"
          v-model="broker"
          item-text="name"
          item-value="url"
          :items="brokerOptions"
          clearable
        />
        <v-select
          required
          label="Carrier*"
          v-model="carrier"
          item-text="name"
          item-value="url"
          :items="carrierOptions"
          clearable
        />
        <ValidationProvider
          rules="two_decimal"
          v-slot="{ errors }"
        >
          <v-text-field
            type="number"
            label="Rate Quote"
            :error-messages="errors"
            v-model="rateQuote"
          />
        </ValidationProvider>
        <rules-currency
          :amount="actualCost"
          @updateAmount="actualCost = $event"
          :currency="currency"
          @updateCurrency="currency = $event"
          :date="actualCostDate"
          @updateDate="actualCostDate = $event"
          show-date-select
          label="Actual Fees"
        />
        <rules-currency
          :amount="additionalFees"
          @updateAmount="additionalFees = $event"
          :currency="additionalFeesCurrency"
          @updateCurrency="additionalFeesCurrency = $event"
          :date="additionalFeesDate"
          @updateDate="additionalFeesDate = $event"
          show-date-select
          label="Additional Fees"
        />
        <ValidationProvider
          v-show="transit.transit_type === 'OUT'"
          rules="max:255"
          v-slot="{ errors }"
        >
          <v-textarea
            label="Shipment Details"
            v-model="shipmentDetails"
            row-height="3"
            :error-messages="errors"
          />
        </ValidationProvider>
        <ValidationProvider
          rules="max:255"
          v-slot="{ errors }"
        >
          <v-textarea
            label="Notes"
            v-model="notes"
            row-height="3"
            :error-messages="errors"
          />
          <p class="text-center red--text font-italic">
            These notes only appear on the Transit View
          </p>
        </ValidationProvider>
        <date-input
          label="Shipped Date"
          v-model="pickupDate"
        />
        <date-input
          label="Delivered Date"
          v-model="deliveryDate"
        />
        <v-alert
          v-if="!isUnshipped || status !== 'NE'"
          dense
          color="warning"
          outlined
        >
          Manifests are only available for modification while transit is 'NEW'
        </v-alert>
        <div v-if="isUnshipped">
          <span>
            Lot Manifests
          </span>
          <lot-manifest-detail-list
            :lot-manifests="lotManifests"
            append-icon="mdi-delete-outline"
            @clicked="removeLotManifest"
          />
          <lot-manifest-search-field
            @selected="addLotManifest"
          />
          <span>
            Inventory Manifests
          </span>
          <inventory-manifest-detail-list
            :inventory-manifests="inventoryManifests"
            append-icon="mdi-delete-outline"
            @clicked="removeInventoryManifest"
          />
          <inventory-manifest-search-field
            @selected="addInventoryManifest"
          />
        </div>
      </v-card-text>
    </edit-dialog>
  </ValidationObserver>
</template>

<script>
import {
  fetchAllBrokers,
  fetchAllCarriers,
  patchTransitById,
  sendOutboundInTransitEmail,
  sendTransitDeliveredEmail
} from '@/requests/logistics'
import LotManifestDetailList from '@/views/Logistics/Transit/components/LotManifestDetailList.vue'
import LotManifestSearchField from '@/views/Logistics/Transit/components/LotManifestSearchField.vue'
import axios from 'axios'
import InventoryManifestSearchField from '@/views/Logistics/Transit/components/InventoryManifestSearchField.vue'
import InventoryManifestDetailList from '@/views/Logistics/Transit/components/InventoryManifestDetailList.vue'

export default {
  name: 'TransitOutboundEditDialog',
  components: {
    InventoryManifestDetailList,
    InventoryManifestSearchField,
    LotManifestSearchField,
    LotManifestDetailList
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    transit: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    deliveredStatuses: ['DE', 'CO'], // delivered, complete
    sendDeliveredEmail: false,
    status: null,
    dialog: null,
    carrier: null,
    broker: null,
    rateQuote: null,
    actualCost: null,
    actualCostDate: new Date().toISOString().slice(0, 10),
    currency: 'USD',
    notes: null,
    shipmentDetails: null,
    pickupDate: null,
    deliveryDate: null,
    carrierOptions: [],
    brokerOptions: [],
    lotManifests: [],
    inventoryManifests: [],
    additionalFees: 0,
    additionalFeesCurrency: 'USD',
    additionalFeesDate: new Date().toISOString().slice(0, 10),
    currencies: ['USD', 'CAD']
  }),
  computed: {
    isUnshipped () {
      return this.transit.status === 'NE'
    },
    availableStatuses () {
      // all statuses available while new
      if (this.isUnshipped) return this.$store.getters['getTransitStatuses']
      return this.$store.getters['getTransitStatuses'].filter(status => status.value !== 'NE')
    },
    sendInTransitEmail () {
      return this.transit.status !== 'IT' && this.status === 'IT'
    }
  },
  watch: {
    status (value, oldVal) {
      const ds = this.deliveredStatuses
      if (oldVal !== null && !ds.includes(oldVal) && ds.includes(value)) {
        this.sendDeliveredEmail = true
      } else {
        this.sendDeliveredEmail = false
      }
    },
    value: {
      handler (opened) {
        if (opened) {
          this.status = this.transit.status
          this.carrier = this.transit.carrier
          this.broker = this.transit.broker
          this.rateQuote = this.transit.rate_quote
          this.actualCost = this.transit.actual_cost
          this.acutalCostDate = this.transit.actual_cost_date
          this.currency = this.transit.actual_cost_denomination
          this.notes = this.transit.notes
          this.shipmentDetails = this.transit.shipment_details
          this.pickupDate = this.transit.pickup_date
          this.deliveryDate = this.transit.delivery_date
          this.lotManifests = this.transit.lot_manifests_details
          this.inventoryManifests = this.transit.inventorymanifest_set_details
          this.additionalFees = this.transit.additional_fees
          this.additionalFeesCurrency = this.transit.additional_fees_denomination
          this.additionalFeesDate = this.transit.additional_fees_date
          this.fetchDependencies()
        }
      }
    }
  },
  methods: {
    addLotManifest (manifest) {
      this.lotManifests.push(manifest)
    },
    removeLotManifest (manifest) {
      this.lotManifests = this.lotManifests.filter(man => man.url !== manifest.url)
    },
    addInventoryManifest (manifest) {
      if (!this.inventoryManifests.find(x => x.id === manifest.id)) {
        this.inventoryManifests.push(manifest)
      }
    },
    removeInventoryManifest (manifest) {
      this.inventoryManifests = this.inventoryManifests.filter(man => man.url !== manifest.url)
    },
    fetchDependencies () {
      if (!this.brokerOptions.length) {
        fetchAllBrokers().then(res => {
          this.brokerOptions = res.data
        })
      }
      if (!this.carrierOptions.length) {
        fetchAllCarriers().then(res => {
          this.carrierOptions = res.data
        })
      }
    },
    saveChanges () {
      const params = {
        status: this.status,
        carrier: this.carrier,
        broker: this.broker,
        rate_quote: this.rateQuote,
        actual_cost: this.actualCost,
        shipment_details: this.shipmentDetails,
        pickup_date: this.pickupDate,
        delivery_date: this.deliveryDate,
        notes: this.notes,
        lot_manifests: this.lotManifests.map(m => m.url),
        inventorymanifest_set: this.inventoryManifests.map(m => m.url),
        actual_cost_denomination: this.currency,
        actual_cost_date: this.acutalCostDate,
        additional_fees: this.additionalFees,
        additional_fees_denomination: this.additionalFeesCurrency,
        additional_fees_date: this.additionalFeesDate
      }

      patchTransitById(this.transit.id, params).then(res => {
        this.$emit('updated', res.data)
        this.$store.commit('setSnackbarSuccess', 'Transit Updated')
        this.dialog = false

        if (this.sendDeliveredEmail) {
          sendTransitDeliveredEmail(this.transit.id).then(() => {
            this.$store.commit('setSnackbarSuccess', 'Transit Delivered Emails Sent')
          })
        }

        if (this.sendInTransitEmail) {
          sendOutboundInTransitEmail(this.transit.id).then(() => {
            this.$store.commit('setSnackbarSuccess', 'In Transit Delivered Emails Sent')
          })
        }
      }).catch(() => {
        this.$logger.error('Failed to update transit')
        this.$store.commit('setSnackbarError', 'Failed to update Transit')
      })
      params.lot_manifests.forEach(url => {
        axios.patch(url, { transit_requested: false }).catch(() => {
          this.$logger.error('Failed to update manifest transit status')
        })
      })
    }
  }
}
</script>
