var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.inventoryBoxes,"items-per-page":-1,"disable-pagination":"","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('strong',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.rowId)+". "+_vm._s(item.name)+" ")]),(item.gps_number)?_c('div',[_c('em',[_vm._v(_vm._s(item.gps_number))])]):_vm._e()]}},{key:"item.contains",fn:function(ref){
var item = ref.item;
return [_c('clickable-contains',{attrs:{"contains":item.contains}}),(item.in_lots)?_c('span',[_vm._l((item.in_lots),function(lot){return _c('div',{key:lot,staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(lot)+" ")])}),_c('div',{staticClass:"text-right"},[_c('small',[_vm._v(" "+_vm._s(item.remarks)+" ")])])],2):_vm._e()]}},{key:"item.initial_weight",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.initial_weight))+" lbs ")])]}},{key:"item.final_weight",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.final_weight))+" lbs ")])]}},{key:"body.append",fn:function(ref){
var header = ref.header;
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"font-weight-bold",attrs:{"colspan":2}},[_vm._v(" Total Weight ")]),_c('td',{staticClass:"font-weight-bold text-right text-no-wrap"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.totalInitialWeight))+" lbs ")]),_c('td',{staticClass:"font-weight-bold text-right text-no-wrap"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.totalFinalWeight))+" lbs ")])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }