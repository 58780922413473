<template>
  <div>
    <div
      v-for="(c, i) in contains"
      :key="i"
    >
      <router-link-id
        v-if="getContainsId(c)"
        name="check-in"
        :id="getContainsId(c)"
      >
        {{ c }}
      </router-link-id>
      <span v-else>
        {{ c }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClickableContains',
  props: {
    contains: {
      type: Array,
      required: true
    }
  },
  methods: {
    /**
     * Attempts to parse the string and get the check in ID
     * @param containString
     * @return {number|boolean|boolean}
     */
    getContainsId (containString) {
      try {
        const regex = /^([\d]+)/gm
        const result = containString.match(regex)
        return result.length > 0 && result.length ? Number(result[0]) : false
      } catch (err) {
        return false
      }
    }
  }
}
</script>
