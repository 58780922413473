<template>
  <div>
    <v-list>
      <v-list-item
        v-for="(lt, index) in transit.transitlot_set"
        :key="index"
      >
        <v-list-item-content>
          <v-list-item-subtitle>
            <div class="d-flex justify-space-between">
              <div class="font-weight-bold">
                {{ lt.lot_name }}
              </div>
              <div>
                <span class="pr-4">
                  {{ lt.weight | formatNumber }} lbs
                </span>
                <span class="pr-4">
                  {{ lt.allocation_percent * 100 }}%
                </span>
                <span>
                  ${{ lt.allocation_value | formatNumber }}
                </span>
                <v-btn
                  icon
                >
                  <v-icon
                    small
                    @click="removeTransitLot(lt)"
                  >
                    mdi-close
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-form
            @submit.prevent="addTransitLot"
          >
            <ValidationObserver
              v-slot="{ invalid }"
              ref="validationObserver"
            >
              <div class="d-flex justify-space-between">
                <div>
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <search-autocomplete
                      v-model="addLotObj"
                      label="Lot Search"
                      item-text="friendlyName"
                      api-path="/api/lots/"
                      :params="{ fields: 'url,id,name' }"
                      :format-entry="lot => Object.assign({}, { friendlyName: lot.name }, lot)"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </div>
                <div class="d-flex align-center">
                  <rules-text
                    validation-rules="required|max_value:999999|decimal:3"
                    v-model="addLotWeight"
                    number="number"
                    class="pr-4"
                    label="Weight lbs"
                  />
                  <v-btn
                    :disabled="invalid"
                    small
                    type="submit"
                  >
                    add
                  </v-btn>
                </div>
              </div>
            </ValidationObserver>
          </v-form>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import RulesText from '@/components/ValidationFields/RulesText.vue'
import SearchAutocomplete from '@/base/components/SearchAutocomplete.vue'
import axios from 'axios'

export default {
  name: 'TransitLotEdit',
  components: { SearchAutocomplete, RulesText },
  props: {
    transit: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    addLotObj: null,
    addLotWeight: 0
  }),
  methods: {
    async addTransitLot () {
      const payload = {
        lot: this.addLotObj.url,
        transit: this.transit.url,
        weight: this.addLotWeight
      }
      const response = await axios.post('/logistics/transit_lots/', payload)
      if (response.status === 201) {
        // Move to parent
        this.addLotWeight = 0
        this.$emit('change')
      }
    },
    async removeTransitLot (transitLot) {
      const response = await axios.delete(transitLot.url)
      if (response.status === 204) {
        this.$emit('change')
      }
    }
  }
}
</script>
