<template>
  <unauthorized
    :authorized="$store.getters.can('create-transit')"
    message="Not Authorized to Create Transit"
  >
    <div>
      <ValidationObserver
        v-slot="{ invalid }"
        ref="validationObserver"
      >
        <v-row>
          <v-col
            offset="1"
            cols="10"
            offset-md="2"
            md="8"
          >
            <base-title>
              Create Transit
            </base-title>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            offset="1"
            cols="10"
            offset-md="2"
            md="8"
          >
            <v-form>
              <v-radio-group
                v-model="transitType"
                row
              >
                <v-radio
                  label="Inbound"
                  value="IN"
                />
                <v-radio
                  label="Outbound"
                  value="OUT"
                />
              </v-radio-group>
              <date-range-input
                :start-date="pickupDate"
                :end-date="deliveryDate"
                start-date-label="Pick Up Date*"
                end-date-label="Delivery Date*"
                @alterStartDate="pickupDate = $event"
                @alterEndDate="deliveryDate = $event"
              />
              <v-row>
                <v-col
                  cols="12"
                >
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <base-location-field
                      v-model="fromLocation"
                      label="Origin*"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <base-location-field
                      v-model="toLocation"
                      label="Destination*"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <shipping-cost-history-table
                    v-if="fromLocation && toLocation"
                    :from-location="fromLocation"
                    :to-location="toLocation"
                  />
                  <div v-else>
                    <em class="subtitle-2">
                      Historic Average displayed when origin an destination selected.
                    </em>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <ValidationProvider
                    rules="two_decimal"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      prepend-inner-icon="mdi-currency-usd"
                      label="Rate Quote"
                      type="number"
                      v-model="rateQuote"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <create-broker-dialog
                    @created="setBroker"
                  >
                    <template v-slot:activator="{ on }">
                      <v-select
                        label="Broker"
                        v-model="broker"
                        item-text="name"
                        :items="brokerOptions"
                        clearable
                        return-object
                        append-outer-icon="mdi-plus"
                        @click:append-outer="on"
                      />
                    </template>
                  </create-broker-dialog>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <create-carrier-dialog
                    :minimal-fields="true"
                    @created="setCarrier"
                  >
                    <template v-slot:activator="{ on }">
                      <ValidationProvider
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-select
                          required
                          label="Carrier*"
                          v-model="carrier"
                          item-text="name"
                          :items="carrierOptions"
                          :error-messages="errors"
                          clearable
                          return-object
                          append-outer-icon="mdi-plus"
                          @click:append-outer="on"
                        />
                      </ValidationProvider>
                    </template>
                  </create-carrier-dialog>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex justify-space-between">
                  <v-btn
                    text
                    @click="$router.go(-1)"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    :color="checkFieldsValid ? 'primary' : ''"
                    @click="create"
                  >
                    Create
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </validationobserver>
    </div>
  </unauthorized>
</template>

<script>
import axios from 'axios'
import CreateCarrierDialog from '@/views/Carriers/component/CreateCarrierDialog'
import CreateBrokerDialog from '@/views/Brokers/component/CreateBrokerDialog'
import BaseLocationField from '@/components/BaseLocationField.vue'
import ShippingCostHistoryTable from '@/components/Transit/ShippingCostHistoryTable.vue'

export default {
  name: 'CreateTransitView',
  components: { ShippingCostHistoryTable, BaseLocationField, CreateBrokerDialog, CreateCarrierDialog },
  data: () => ({
    transitType: 'IN',
    brokerOptions: [],
    carrierOptions: [],
    broker: null,
    carrier: null,
    rateQuote: 0,
    pickupDate: '',
    deliveryDate: '',
    fromLocation: null,
    toLocation: null
  }),
  computed: {
    checkFieldsValid () {
      return Boolean(
        this.pickupDate &&
        this.deliveryDate &&
        this.fromLocation &&
        this.toLocation &&
        this.carrier
      )
    }
  },
  watch: {
    fromLocation () {
      this.fetchHistory()
    },
    toLocation () {
      this.fetchHistory()
    }
  },
  methods: {
    /**
     * Fetches a actual cost history based on locations
     * @return {Promise<void>}
     */
    async fetchHistory () {
      if (!this.fromLocation) return
      if (!this.toLocation) return
      const params = { from_address: this.fromLocation.id, to_address: this.toLocation.id }
      const r = await axios.get('/logistics/transits/rate_history/', { params })
      if (r.status !== 200) return
      this.historicAvg = r.data.actual_cost__avg
      this.history = r.data.history
    },
    // For setting a broker directly
    setBroker (broker) {
      this.brokerOptions.push(broker)
      this.broker = broker
    },
    // For setting a carrier directly
    setCarrier (carrier) {
      this.carrierOptions.push(carrier)
      this.carrier = carrier
    },
    create () {
      if (!this.checkFieldsValid) {
        this.$store.commit('setSnackbarWarning', 'Missing required fields')
        return
      }
      let transit = {
        'transit_type': this.transitType,
        'from_address': this.fromLocation.url,
        'pickup_date': this.pickupDate,
        'delivery_date': this.deliveryDate,
        'to_address': this.toLocation.url,
        'carrier': this.carrier.url,
        'rate_quote': Math.round(this.rateQuote * 100) / 100,
        'lot_manifests': [],
        'inventorymanifest_set': []
      }
      if (this.broker) transit.broker = this.broker.url
      return axios.post('/logistics/transits/', transit).then(res => {
        this.$logger.debug('Transit Created ' + res.data.id)
        return this.$router.push({ name: 'transit-view', params: { id: res.data.id } })
      })
    },
    // Does an api request to get all required carriers
    fetchCarriers () {
      axios.get('/logistics/carriers/?page_size=0&status=GS&fields=url,name').then(r => {
        if (r.status === 200) this.carrierOptions = r.data
      })
    },
    // Does an api request to get all required carriers
    fetchBrokers () {
      axios.get('/logistics/brokers/?page_size=0&status=GS&fields=url,name').then(r => {
        if (r.status === 200) this.brokerOptions = r.data
      })
    }
  },
  mounted () {
    if (this.$route.query['transit_type']) {
      this.transitType = this.$route.query['transit_type']
    }
    this.fetchCarriers()
    this.fetchBrokers()
  }
}
</script>
