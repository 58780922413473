var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("activator",null,{"on":function () { return _vm.dialog = !_vm.dialog; }}),_c('ValidationObserver',{ref:"validationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('edit-dialog',{attrs:{"inputs-valid":_vm.fieldsValid && !invalid,"headline-text":"Create Transit"},on:{"accept":_vm.accept},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-row',[_c('v-col',{attrs:{"offset":"1","cols":"10","offset-md":"2","md":"8"}},[_c('v-form',{attrs:{"disabled":!_vm.$store.getters.can('create-transit')}},[_c('date-range-input',{attrs:{"start-date":_vm.pickupDate,"end-date":_vm.deliveryDate,"start-date-label":"Pick Up Date*","end-date-label":"Delivery Date*"},on:{"alterStartDate":function($event){_vm.pickupDate = $event},"alterEndDate":function($event){_vm.deliveryDate = $event}}}),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"item-text":"name","return-object":"","label":"Origin*","hint":"Origins are based on requests","items":_vm.getOriginOptions,"error-messages":errors},on:{"change":_vm.fetchHistory},model:{value:(_vm.fromLocation),callback:function ($$v) {_vm.fromLocation=$$v},expression:"fromLocation"}}),(_vm.fromLocation)?_c('location-block',{attrs:{"location":_vm.fromLocation}}):_vm._e()]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"item-text":"name","return-object":"","label":"Destination*","items":_vm.getDestinationOptions,"error-messages":errors},on:{"change":_vm.fetchHistory},model:{value:(_vm.toLocation),callback:function ($$v) {_vm.toLocation=$$v},expression:"toLocation"}}),(_vm.toLocation)?_c('location-block',{attrs:{"location":_vm.toLocation}}):_vm._e()]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[(_vm.fromLocation && _vm.toLocation)?_c('shipping-cost-history-table',{attrs:{"from-location":_vm.fromLocation,"to-location":_vm.toLocation}}):_c('div',[_c('em',{staticClass:"subtitle-2"},[_vm._v(" Historic Average displayed when origin an destination selected. ")])])],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"two_decimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-currency-usd","label":"Rate Quote","type":"number","error-messages":errors},model:{value:(_vm.rateQuote),callback:function ($$v) {_vm.rateQuote=$$v},expression:"rateQuote"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"label":"Broker","item-text":"name","items":_vm.brokerOptions,"clearable":"","return-object":""},model:{value:(_vm.broker),callback:function ($$v) {_vm.broker=$$v},expression:"broker"}}),_c('p',{staticClass:"text-right"},[_c('create-broker-dialog',{on:{"created":_vm.setBroker},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.$store.getters.can('create-broker'))?_c('v-btn',{attrs:{"disabled":!!_vm.broker,"text":""},on:{"click":on}},[_vm._v(" New Broker ")]):_vm._e()]}}],null,true)})],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"required":"","label":"Carrier*","item-text":"name","items":_vm.carrierOptions,"clearable":"","return-object":""},model:{value:(_vm.carrier),callback:function ($$v) {_vm.carrier=$$v},expression:"carrier"}}),_c('p',{staticClass:"text-right"},[_c('create-carrier-dialog',{attrs:{"minimal-fields":true},on:{"created":_vm.setCarrier},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.$store.getters.can('create-carrier'))?_c('v-btn',{attrs:{"disabled":!!_vm.carrier,"text":""},on:{"click":on}},[_vm._v(" New Carrier ")]):_vm._e()]}}],null,true)})],1)],1)],1)],1)],1)],1)],1)]}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }