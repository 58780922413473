<template>
  <div v-if="transit">
    <edit-dialog
      :value="value"
      :headline-text="`Received Transit #${transit.id}`"
      :show-decline="false"
      accept-text="Close"
      @input="$emit('input', $event)"
    >
      <v-progress-linear
        v-if="updatingCost"
        indeterminate
      />
      <v-toolbar
        flat
        dense
      >
        <user-upload-dialog
          :file-links="fileLinks"
        />
        <v-spacer />
        <info-tooltip>
          <template v-slot:wrap-me>
            <v-btn
              :disabled="!actualCost || !$store.getters.can('complete-transit')"
              color="primary"
              small
              @click="confirmCompleteDialog = !confirmCompleteDialog"
            >
              Complete Transit
            </v-btn>
          </template>
          Transits will be automatically completed.<br>
          - Actual Cost is not $0<br>
          - All Check Ins have a Intake<br>
          However you may also manually Complete them here.
        </info-tooltip>
      </v-toolbar>
      <v-card-text>
        <h1>
          {{ title }}
          <router-link
            target="_blank"
            :to="{ name: 'transit-view', params: { id: transit.id }}"
          >
            <v-icon>
              mdi-open-in-new
            </v-icon>
          </router-link>
          <info-tooltip>
            Broker / Carrier
          </info-tooltip>
        </h1>
        <v-row v-if="transit">
          <v-col>
            <p>
              Booked By: {{ transit.booked_by__username }} on {{ transit.booked_at | formatDate }}
              <br>
              Received By: {{ transit.received_by__username }} on {{ transit.received_at | formatDate }}
              <br>
              <span
                v-if="$store.getters.can('view-rate-quote')"
              >
                Quoted Rate: {{ transit.rate_quote | currencyUSD }}
              </span>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              :disabled="!$store.getters.can('edit-transit')"
              label="Invoice Number"
              v-model="invoiceNumber"
              @input="saveCost"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <date-input
              :disabled="!$store.getters.can('edit-transit')"
              v-model="invoiceDate"
              label="Invoice Date"
              @input="saveCost"
            />
          </v-col>
        </v-row>
        <v-row v-if="$store.getters.can('edit-transit')">
          <v-col>
            <rules-currency
              :amount="actualCost"
              @updateAmount="actualCost = $event"
              :currency="actualCostCurrency"
              @updateCurrency="actualCostCurrency = $event"
              :date="actualCostDate"
              @updateDate="actualCostDate = $event"
              show-date-select
              label="Actual Fees"
              @changed="saveCost"
            />
            <rules-currency
              :amount="additionalFees"
              @updateAmount="additionalFees = $event"
              :currency="additionalFeesCurrency"
              @updateCurrency="additionalFeesCurrency = $event"
              :date="additionalFeesDate"
              @updateDate="additionalFeesDate = $event"
              show-date-select
              label="Additional Fees"
              @changed="saveCost"
            />
          </v-col>
        </v-row>
        <v-row v-else>
          Editing Fees disabled (requires edit-transit permission)
        </v-row>
        <v-row>
          <v-col>
            <div class="text-caption">
              Lot Allocations
            </div>
            <transit-lot-edit
              :transit="transit"
              @change="$store.dispatch('transitDashboardStore/editTransit/refreshTransit', transit.url)"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              :disabled="!$store.getters.can('edit-transit')"
              label="Fee Description/Rate Adjustment"
              v-model="feeDescription"
              @input="saveCost"
            />
          </v-col>
        </v-row>
        <h3 v-if="disputeNotes">
          Notes (Dispute)
        </h3>
        <v-row v-if="disputeNotes">
          <v-col>
            <p>
              {{ disputeNotes }}
            </p>
          </v-col>
        </v-row>
        <h3>
          Dates / Destinations
        </h3>
        <v-row>
          <v-col cols="5">
            <strong>{{ pickupDate | formatDate }}</strong>
            <location-block :location="fromLocation" />
          </v-col>
          <v-col cols="2">
            <v-icon>
              mdi-arrow-right
            </v-icon>
          </v-col>
          <v-col cols="5">
            <strong>{{ transit.received_at | formatDate }}</strong>
            <location-block :location="toLocation" />
          </v-col>
        </v-row>
        <h3>
          Attached Requests
        </h3>
        <request-tile-list
          :request-list="attachedRequests()"
        />
        <received-boxes
          v-if="!loadingBoxes"
          readonly
        />
        <v-skeleton-loader
          v-else
          type="headline,table"
        />
      </v-card-text>
    </edit-dialog>
    <edit-dialog
      headline-text="Confirm Transit Complete"
      v-if="confirmCompleteDialog"
      v-model="confirmCompleteDialog"
      decline-text="Wait"
      accept-text="Confirm"
      @accept="dispatchCompleteTransit"
    >
      <v-card-text>
        By Clicking Confirm this transit status will be changed to complete and will no longer appear on the Transit
        Dashboard.
      </v-card-text>
    </edit-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import LocationBlock from '@/views/Logistics/Transit/components/LocationBlock'
import ReceivedBoxes from '@/views/Logistics/Transit/components/ReceivedBoxes'
import RequestTileList from '@/views/Logistics/Transit/components/RequestTileList'
import utils from '@/helpers/transitUtils'
import UserUploadDialog from '@/base/components/UserUploadDialog'
import fileLinks from '@/helpers/fileLinks.js'
import TransitLotEdit from '@/views/Logistics/Transit/components/TransitLotEdit.vue'

export default {
  name: 'EditReceivedTransit',
  components: { TransitLotEdit, UserUploadDialog, RequestTileList, ReceivedBoxes, LocationBlock },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      feeDescription: '',
      updatingCostTimeout: null,
      updatingCost: false,
      disputeNotes: '',
      status: null,
      pickupDate: '',
      deliveryDate: '',
      fromLocation: null,
      toLocation: null,
      onlyMatchingDestination: true,
      receiveDialog: false,
      confirmCompleteDialog: false,
      loadingBoxes: false,
      invoiceNumber: '',
      invoiceDate: null,
      // Currency Fields
      actualCost: 0,
      actualCostCurrency: this.$store.getters.getCurrencyDefault?.value ?? 'USD',
      actualCostDate: new Date().toISOString().slice(0, 10),
      additionalFees: 0,
      additionalFeesCurrency: this.$store.getters.getCurrencyDefault?.value ?? 'USD',
      additionalFeesDate: new Date().toISOString().slice(0, 10)
    }
  },
  watch: {
    updatingCost (value) {
      if (!value) {
        this.$store.dispatch('transitDashboardStore/editTransit/refreshTransit', this.transit.url)
      }
    },
    transit: {
      immediate: true,
      handler (transit) {
        if (transit) {
          this.feeDescription = transit.fee_description
          this.status = transit.status
          this.disputeNotes = transit.dispute_notes
          this.pickupDate = transit.pickup_date
          this.deliveryDate = transit.delivery_date
          this.fromLocation = transit.from_address_details
          this.toLocation = transit.to_address_details
          this.invoiceNumber = transit.invoice_number
          this.invoiceDate = transit.invoice_date
          // Currency Fields
          this.actualCost = transit.actual_cost
          this.actualCostCurrency = transit.actual_cost_denomination
          this.actualCostDate = transit.actual_cost_date
          this.additionalFees = transit.additional_fees
          this.additionalFeesCurrency = transit.additional_fees_denomination
          this.additionalFeesDate = transit.additional_fees_date
        }
      }
    },
    value (opened) {
      if (opened) {
        this.loadingBoxes = true
        this.$store.dispatch('transitDashboardStore/editTransit/fetchBoxes').finally(() => {
          this.loadingBoxes = false
        })
      }
    }
  },
  computed: {
    currencies () {
      return this.$store.getters.configCurrenciesFiltered
    },
    fileLinks () {
      return fileLinks.transitFileLink(this.transit)
    },
    title () {
      let x = []
      if (this.transit.broker_data) x.push(this.transit.broker_data.name)
      if (this.transit.carrier_data) x.push(this.transit.carrier_data.name)
      return x.join(' / ')
    },
    transit () {
      return this.$store.getters['transitDashboardStore/editTransit/transit']
    }
  },
  methods: {
    ...mapActions('transitDashboardStore', [
      'patchTransit'
    ]),
    attachedRequests () {
      return utils.requestList(this.transit)
    },
    /**
     * Makes an api call to update the cost value and fee description
     */
    saveCost () {
      this.updatingCost = true
      if (this.updatingCostTimeout) clearTimeout(this.updatingCostTimeout)
      this.updatingCostTimeout = setTimeout(async () => {
        const transitUrl = this.transit.url
        const payload = {
          fee_description: this.feeDescription,
          invoice_number: this.invoiceNumber,
          invoice_date: this.invoiceDate
        }

        // Currency fields
        if (Number(this.actualCost) >= 0) {
          payload.actual_cost = this.actualCost
          payload.actual_cost_denomination = this.actualCostCurrency
          payload.actual_cost_date = this.actualCostDate
        }

        if (Number(this.additionalFees) >= 0) {
          payload.additional_fees = this.additionalFees
          payload.additional_fees_denomination = this.additionalFeesCurrency
          payload.additional_fees_date = this.additionalFeesDate
        }

        await this.patchTransit({ transitUrl, payload })
        this.updatingCost = false
      }, 1000)
    },
    /**
     * Ability to close out a transit entry as received
     */
    dispatchCompleteTransit () {
      this.$store.dispatch('transitDashboardStore/editTransit/completeTransit')
      this.$emit('input', false)
    }
  }
}
</script>
