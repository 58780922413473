<!--
Edit Request dialog houses everything required for editing request

edit toLocation
edit requires_transit
-->
<template>
  <div v-if="!!request">
    <ValidationObserver
      v-slot="{ invalid }"
      ref="validationObserver"
    >
      <edit-dialog
        :value="value"
        :inputs-valid="!invalid"
        :headline-text="`Edit Transit Request #${request.id}`"
        @input="$emit('input', $event)"
        @accept="updateRequest"
      >
        <v-card-title>
          {{ request.name }}
        </v-card-title>
        <v-card-text>
          <location-block
            v-if="request.current_location"
            :location="request.current_location"
          />
          <transit-properties :properties="request.transit_properties" />
          <br>
          <ValidationProvider
            rules="required"
            v-slot="{ errors }"
          >
            <v-select
              v-if="request.type !== 'LOT_MANIFEST'"
              v-model="toLocation"
              item-text="name"
              return-object
              label="Destination*"
              :items="$store.getters['transitDashboardStore/getDestinationOptions']"
              :error-messages="errors"
            />
            <location-block
              v-if="toLocation"
              :location="toLocation"
            />
          </ValidationProvider>
        </v-card-text>
        <template
          v-if="$store.getters.can('cancel-transit-request')"
          #actionBtn
        >
          <v-btn
            text
            @click="cancelRequest"
            :disabled="request.type === 'CHECKIN' && request.status === 'PR'"
          >
            Remove Request
          </v-btn>
          <info-tooltip v-if="request.type === 'CHECKIN' && request.status === 'PR'">
            Cannot cancel a Preliminary Check-In Request
          </info-tooltip>
        </template>
      </edit-dialog>
    </validationobserver>
  </div>
</template>

<script>
import LocationBlock from '@/views/Logistics/Transit/components/LocationBlock'
import TransitProperties from '@/views/Logistics/Transit/components/TransitProperties'

export default {
  name: 'EditRequest',
  components: { TransitProperties, LocationBlock },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    toLocation: null
  }),
  watch: {
    request: {
      immediate: true,
      handler (request) {
        if (request) {
          this.toLocation = request.to_location
        }
      }
    }
  },
  computed: {
    request () {
      return this.$store.getters['transitDashboardStore/requests/getRequestToEdit']
    }
  },
  methods: {
    /**
     * Updates the toLocation this request
     */
    updateRequest () {
      let request = this.request
      request.toLocation = this.toLocation
      this.$store.dispatch('transitDashboardStore/requests/patchTransitRequest', request)
      this.$emit('input', false)
    },
    /**
     * Set transit request as false, remove from list
     */
    cancelRequest () {
      let request = this.request
      this.$store.dispatch('transitDashboardStore/requests/removeRequest', request)
      this.$emit('input', false)
    }
  }
}
</script>
