var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" Shipping Cost History ")]),(_vm.history.length)?_c('v-data-table',{attrs:{"headers":[
      { text: 'Transit Id', value: 'id' },
      { text: 'Actual Cost', value: 'actual_cost' },
      { text: 'Booked On', value: 'booked_at' }
    ],"items":_vm.history},scopedSlots:_vm._u([{key:"item.actual_cost",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyUSD")(item.actual_cost))+" ")]}},{key:"item.booked_at",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.booked_at))+" ")]}},{key:"body.append",fn:function(){return [_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v(" Average ")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("currencyUSD")(_vm.historicAvg))+" ")]),_c('td')])]},proxy:true}],null,false,673192920)}):_c('v-card-text',[_vm._v(" No history to show ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }