<template>
  <unauthorized
    :authorized="$store.getters.can('view-inventory-on-hand')"
    message="Unauthorized to view Inventory on Hand"
  >
    <ValidationObserver
      ref="observer"
      v-slot="{ invalid }"
    >
      <v-form @submit.stop.prevent="save">
        <v-container
          fluid
          class="mb-16"
        >
          <url-breadcrumbs
            :items="[
              ['Inventory Manifest List', { name: 'manifest-list' }],
              ['Manifest Create', { name: 'manifest-create' }]
            ]"
          />
          <base-title title="Create Manifest" />
          <v-card class="mt-4">
            <v-card-title>
              Details
            </v-card-title>
            <v-card-text>
              <rules-date
                label="Request Pick Up Date"
                validation-rules="required"
                v-model="pickUpDate"
              />
              <ValidationProvider
                v-slot="{ errors }"
              >
                <v-textarea
                  label="Remarks"
                  v-model="remarks"
                  counter
                  auto-grow
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-card-text>
            <v-card-title>
              Shipping Origin
            </v-card-title>
            <v-card-text>
              <ValidationProvider
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                  v-model="originUrl"
                  label="Inventory Location (Inventory Location Select)"
                  clearable
                  :items="inventoryLocations"
                  item-text="name"
                  item-value="url"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-card-text>
            <v-card-title>
              Shipping Destination
            </v-card-title>
            <v-card-actions>
              <v-switch
                v-model="useDestinationSearch"
                label="Use Location Search"
                dense
              />
            </v-card-actions>
            <v-card-text>
              <ValidationProvider
                v-if="!useDestinationSearch"
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                  v-model="destinationUrl"
                  label="Inventory Destination (Inventory Location Select)"
                  :items="inventoryLocations"
                  item-text="name"
                  item-value="url"
                  :error-messages="errors"
                />
              </ValidationProvider>
              <div v-else>
                <search-autocomplete
                  v-model="destinationSearched"
                  label="Inventory Destination (Location Search)"
                  api-path="/logistics/locations/"
                  :format-entry="formatName"
                />
                <div
                  v-show="destinationError"
                  class="error--text"
                >
                  {{ destinationError }}
                </div>
              </div>
              <em>
                Box selection is available after the manifest is created.
              </em>
              <v-checkbox
                v-model="requiresTransit"
                label="Requires Transit? (sends email to logistics team)"
              />
            </v-card-text>
          </v-card>
          <text-fab
            :disabled="invalid || !!destinationError"
            bottom
            right
            fixed
            icon-text="mdi-check"
            color="primary"
            type="submit"
          >
            Save
          </text-fab>
        </v-container>
      </v-form>
    </ValidationObserver>
  </unauthorized>
</template>

<script>
import moment from 'moment/moment'
import RulesDate from '../../components/ValidationFields/RulesDate.vue'
import SearchAutocomplete from '../../base/components/SearchAutocomplete.vue'
import { fetchInventoryLocations } from '../../requests/inventoryBoxes'
import { createInventoryManifest } from '../../requests/inventoryManifests'

const today = moment().format('YYYY-MM-DD')

export default {
  name: 'ManifestCreateView',
  components: { SearchAutocomplete, RulesDate },
  data: () => ({
    remarks: '',
    pickUpDate: today,
    // aka current location
    originUrl: null,
    destinationUrl: null,
    destinationSearched: null,
    useDestinationSearch: false,
    inventoryLocations: [],
    requiresTransit: false
  }),
  computed: {
    destinationError () {
      return (this.useDestinationSearch && !this.destinationSearched) ? 'Destination Required' : null
    }
  },
  methods: {
    formatName (location) {
      let friendlyName = `${location.name}: ${location.address} ${location.city}, ${location.state}`
      while ([' ', ',', ':'].includes(friendlyName[friendlyName.length - 1])) {
        friendlyName = friendlyName.slice(0, -1)
      }
      return Object.assign(location, { friendlyName })
    },
    save () {
      let tl
      if (this.useDestinationSearch) {
        tl = this.destinationSearched?.url
      } else {
        tl = this.destinationUrl
      }

      if (!tl) {
        this.$store.commit('setSnackbarError', 'Something went wrong getting destination')
        return
      }

      let payload = {
        remarks: this.remarks,
        request_date: this.pickUpDate,
        transit_requested: this.requiresTransit,
        current_location: this.originUrl,
        to_location: tl
      }

      if (payload.current_location === payload.to_location) {
        this.$store.commit('setSnackbarWarning', 'The origin and destination are the same')
        return
      }

      createInventoryManifest(payload).then(res => {
        if (res.status === 201) {
          this.$store.commit('setSnackbarSuccess', 'Manifest Created #' + res.data.id)
          this.$router.push({ name: 'manifest-view', params: { id: res.data.id } })
        } else {
          this.$store.commit('setSnackbarError', 'Failed to create manifest')
        }
      })
    },
    async loadData () {
      await fetchInventoryLocations().then(response => {
        this.inventoryLocations = response.data
      })
    }
  },
  async mounted () {
    this.loading = true
    await this.loadData()
    this.loading = false
  }
}
</script>
