/**
 * Retrieves transit Request into a single list
 * @param transit
 * @return {FlatArray<*[], number>[]}
 */
function requestList (transit) {
  let listOfLists = []
  if (transit) {
    if (transit.manifests) listOfLists.push(transit.manifests)
    if (transit.check_ins) listOfLists.push(transit.check_ins)
  }
  return listOfLists.flat(1)
}

export default {
  requestList
}
