<template>
  <div>
    <v-card flat>
      <v-autocomplete
        v-model="selected"
        :items="results"
        :loading="isLoading"
        :search-input.sync="searchText"
        :item-text="formatText"
        label="Search Lot Manifests by Lot Name"
        placeholder="Start typing to Search Lot Manifests w/o Transit"
        return-object
        @change="emitSelected"
      />
    </v-card>
  </div>
</template>

<script>
import { findAllTransitRequestedLotManifests, findDetachedLotManifestByLotName } from '@/requests/logistics'

export default {
  name: 'LotManifestSearchField',
  data: () => ({
    selected: null,
    isLoading: false,
    results: [],
    searchText: '',
    timeout: null
  }),
  watch: {
    selected (item) {
      if (item) this.$emit('selected', item)
    },
    searchText (text) {
      if (!text) return
      if (this.timeout) clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {
        this.isLoading = true
        findDetachedLotManifestByLotName(text).then(res => {
          this.results = res.data.results
        }).finally(() => {
          this.isLoading = false
        })
      }, 300)
    }
  },
  methods: {
    formatText (manifest) {
      let lotNames = []
      if (manifest.lots__details) {
        manifest.lots__details.forEach(lot => {
          lotNames.push(lot.name)
        })
      }
      return manifest.id + '. ' + lotNames.join(', ')
    },
    emitSelected () {
      if (this.selected) {
        this.$emit('selected', this.selected)
        this.selected = null
      }
    }
  },
  mounted () {
    // Populate some default starting entries
    findAllTransitRequestedLotManifests().then(response => {
      this.results = response.data
    }).catch(() => {
      this.$store.commit('setSnackbarWarning', 'Failed to get detached lot manifests')
    })
  }
}
</script>
