<template>
  <div>
    <slot
      name="activator"
      :toggle="() => dialog = !dialog"
    />
    <v-dialog
      v-model="dialog"
      fullscreen
    >
      <v-card>
        <v-toolbar>
          <v-toolbar-title>
            Add / Remove Manifest {{ manifest.id }} Lines
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              @click="dialog = false"
            >
              cancel
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-title>
          Edit Lines
        </v-card-title>
        <v-card-text>
          <p>
            Select the boxes you would like to attach to Manifest <strong>#{{ manifest.id }}</strong>.<br>
            The current weight is <strong>{{ totalWeight | formatNumber }} lbs</strong> with a total of <strong>{{ totalPieces | formatNumber }} pieces</strong>.
          </p>
          <inventory-box-select
            v-model="selectedBoxes"
            :search-filters="manifest.current_location__name ? { location_name: manifest.current_location__name } : {}"
          />
        </v-card-text>
        <text-fab
          bottom
          right
          fixed
          icon-text="mdi-check"
          color="primary"
          @click="saveBoxes"
        >
          Save
        </text-fab>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import InventoryBoxSelect from '../../Warehouse/components/InventoryBoxSelect.vue'
import { patchInventoryManifest } from '../../../requests/inventoryManifests'

export default {
  name: 'ManifestLinesEditDialog',
  components: { InventoryBoxSelect },
  props: {
    manifest: {
      type: Object,
      required: true
    },
    boxes: {
      type: Array,
      default: Array
    }
  },
  data: () => ({
    selectedBoxes: [],
    dialog: false
  }),
  computed: {
    totalWeight () {
      return this.selectedBoxes.reduce((accum, box) => accum + Number(box.weight), 0)
    },
    totalPieces () {
      return this.selectedBoxes.reduce((accum, box) => accum + Number(box.pieces), 0)
    }
  },
  watch: {
    dialog: {
      handler (opened) {
        if (opened) {
          this.selectedBoxes = this.boxes
        }
      },
      immediate: true
    }
  },
  methods: {
    saveBoxes () {
      patchInventoryManifest(this.manifest.id, { boxes: this.selectedBoxes.map(b => b.url) }).then(response => {
        if (response.status === 200) {
          this.$store.commit('setSnackbarMessage', 'Updated Boxes Successful')
          this.dialog = false
          this.$emit('updated')
        } else {
          this.$store.commit('setSnackbarError', 'Boxes Failed to Update')
        }
      })
    }
  }
}
</script>
