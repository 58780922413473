<template>
  <unauthorized
    :authorized="$store.getters.can('view-manifests')"
    message="Not Authorized to view Manifests"
  >
    <base-title title="Manifest List" />
    <search-filters
      v-model="search"
      :chips="chips"
      @clearChip="clearChipProp"
      @clearFields="clearFields"
      @submitSearch="routePage"
      @advancedOpened="opened"
    >
      <date-range-input
        :start-date="createdStartDate"
        :end-date="createdEndDate"
        start-date-label="Creation Date From"
        end-date-label="Creation Date To"
        @alterStartDate="createdStartDate = $event"
        @alterEndDate="createdEndDate = $event"
      />
      <date-range-input
        :start-date="puStartDate"
        :end-date="puEndDate"
        start-date-label="Pick Up From"
        end-date-label="Pick Up To"
        @alterStartDate="puStartDate = $event"
        @alterEndDate="puEndDate = $event"
      />
      <v-row>
        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">
          <v-text-field
            label="ID"
            v-model="id"
            type="number"
          />
        </v-col>
        <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6">
          <v-text-field
            label="Created By"
            v-model="createdBy"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            label="Current Location"
            v-model="currentLocation"
            :items="locations"
            clearable
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            label="Destination"
            v-model="toLocation"
            :items="locations"
            clearable
          />
        </v-col>
      </v-row>
    </search-filters>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />
    <v-data-table
      multi-sort
      :headers="headers"
      :items="resultList"
      :server-items-length="totalCount"
      :options.sync="pagination"
      :footer-props="{ 'items-per-page-options': [20] }"
      @page-count="pageCount = $event"
      hide-default-footer
    >
      <template v-slot:item.id="{ item }">
        <router-link-id
          :id="item.id"
          name="manifest-view"
        />
      </template>
      <template v-slot:item.request_date="{ item }">
        {{ item.request_date | formatDate }}
      </template>
      <template v-slot:item.pick_up_date="{ item }">
        {{ item.pick_up_date | formatDate }}
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | formatDate }}
      </template>
      <template v-slot:item.in_transit="{ item }">
        <v-icon
          color="success lighten-1"
          v-if="item.in_transit"
        >
          mdi-circle
        </v-icon>
      </template>
    </v-data-table>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />
    <text-fab
      v-if="$store.getters.can('create-manifests')"
      bottom
      right
      fixed
      icon-text="mdi-plus"
      color="primary"
      @click="$router.push({ name: 'manifest-create' })"
    >
      Create Manifest
    </text-fab>
  </unauthorized>
</template>

<script>
import axios from 'axios'
import chipsMixin from '@/base/filter/chipsMixin'
import paginationMixin from '@/base/filter/paginationMixin'
import SearchFilters from '@/base/filter/SearchFilters'
import ResultPagination from '@/base/components/ResultPagination'

export default {
  components: { ResultPagination, SearchFilters },
  mixins: [chipsMixin, paginationMixin],
  name: 'ManifestList',
  data: function () {
    return {
      pageName: 'manifest-list',
      headers: [
        { text: 'id', value: 'id' },
        { text: 'Remarks', value: 'remarks' },
        // { text: 'transit_requested', value: 'transit_requested' },
        { text: 'Transit Req.', value: 'request_date' },
        { text: 'Pick Up Date', value: 'pick_up_date' },
        { text: 'Location', value: 'current_location__name' },
        { text: 'Destination', value: 'to_location__name' },
        { text: 'In Transit', value: 'in_transit' },
        { text: 'Created At', value: 'created_at' },
        { text: 'Created By', value: 'created_by__username' }
      ],

      ...this.chipPropagation([
        { name: 'ID', prop: 'id', type: String, query: 'id' },
        { name: 'Location', prop: 'currentLocation', type: String, query: 'current_location__name' },
        { name: 'Destination', prop: 'toLocation', type: String, query: 'to_location__name' },
        // { name: 'In Transit', prop: 'inTransit', type: String, query: 'in_transit' },
        { name: 'Created By', prop: 'createdBy', type: String, query: 'created_by__username__icontains' },
        { name: 'Start Pick Up', prop: 'puStartDate', type: String, query: 'pick_up_date__gte' },
        { name: 'End Pick Up', prop: 'puEndDate', type: String, query: 'pick_up_date__lte' },
        { name: 'Start Create Date', prop: 'createdStartDate', type: String, query: 'created_at__gte' },
        { name: 'End Create Date', prop: 'createdEndDate', type: String, query: 'created_at__lte' }
      ])
    }
  },
  watch: {
    // observes and updated options
    pagination: function (updated) {
      this.routePage(updated)
    }
  },
  computed: {
    locations () {
      return this.$store.getters['getConfigLocations'].map(x => x.name)
    }
  },
  methods: {
    opened () {
      this.$store.dispatch('fetchConfig')
    },
    async executeSearch () {
      const params = this.getQueryParams()
      params['fields'] = [
        'url',
        'id',
        'remarks',
        'transit_requested',
        'request_date',
        'current_location__name',
        'to_location__name',
        'pick_up_date',
        'transit',
        'in_transit',
        'created_by',
        'created_by__username',
        'created_at'
      ].join(',')
      axios.get('/api/inventory_manifests/', { params }).then(this.setResponseResults)
    }
  },
  mounted () {
    this.reflectRouteParams()
    this.executeSearch()
  }
}
</script>
