<template>
  <v-sheet
    rounded
    outlined
    class="my-2"
  >
    <v-list-item
      :disabled="disabled"
      dense
      @click.stop="$emit('click', request)"
    >
      <v-list-item-content>
        <v-list-item-title>
          <v-icon
            class="pb-1"
            x-small
            :color="color"
          >
            mdi-circle
          </v-icon>
          <router-link-id
            class="pr-1"
            :id="request.id"
            :name="linkName"
          >
            <strong>
              {{ request.name }}
            </strong>
          </router-link-id>
        </v-list-item-title>
        <slot
          :request="request"
        />
      </v-list-item-content>
    </v-list-item>
  </v-sheet>
</template>

<script>
export default {
  name: 'RequestTile',
  props: {
    request: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    linkName () {
      switch (this.request.type) {
        case 'MANIFEST':
          return 'manifest-view'
        case 'CHECKIN':
          return 'check-in'
        case 'LOT_MANIFEST':
          return 'lot-manifest'
        default:
          return ''
      }
    },
    color () {
      switch (this.request.type) {
        case 'MANIFEST':
          return 'deep-purple'
        case 'CHECKIN':
          return 'teal'
        case 'LOT_MANIFEST':
          return 'pink'
        default:
          return 'primary'
      }
    }
  }
}
</script>
