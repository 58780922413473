<!--
Show the booked transit list
Child of TransitDashboard
-->
<template>
  <div>
    <h4>
      Booked / Transit
      <info-tooltip>
        Shows all Transit entries that have not been RECEIVED/DELIVERED.
      </info-tooltip>
    </h4>
    <v-sheet
      style="max-height: 75vh"
      class="overflow-y-auto pr-2"
    >
      <booked-transit
        v-for="booked in $store.getters['transitDashboardStore/getBookedTransits']"
        :key="booked.id"
        :transit="booked"
        @click="editTransit(booked)"
      />
    </v-sheet>
    <edit-transit
      v-model="show"
    />
  </div>
</template>

<script>
import BookedTransit from '@/views/Logistics/Transit/components/BookedTransit'
import EditTransit from '@/views/Logistics/Transit/components/EditTransit'
export default {
  name: 'BookedTransits',
  components: { EditTransit, BookedTransit },
  data: () => ({
    show: false
  }),
  methods: {
    /**
     * Sets transit edit dialog selection
     * @param transit
     */
    editTransit (transit) {
      this.$store.commit('transitDashboardStore/editTransit/setTransit', transit)
      this.show = true
    }
  }
}
</script>
