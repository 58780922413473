<template>
  <v-card
    class="my-1"
    @click="$emit('click')"
  >
    <v-card-title class="subtitle-2">
      {{ transit.name }}
    </v-card-title>
    <v-card-subtitle class="text-sm-subtitle-2">
      <small v-if="transit.from_address_details">
        <info-tooltip>
          <template v-slot:wrap-me>
            {{ transit.from_address_details.name }}
          </template>
          <location-block :location="transit.from_address_details" />
        </info-tooltip>
      </small>
      <br>
      {{ transit.delivery_date | formatDate }}<info-tooltip>Delivery Date</info-tooltip>
    </v-card-subtitle>
    <v-card-text>
      <request-tile-list
        :request-list="attachedRequests()"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import LocationBlock from './LocationBlock'
import utils from '@/helpers/transitUtils'
import RequestTileList from '@/views/Logistics/Transit/components/RequestTileList'

export default {
  name: 'ReceivedTransit',
  components: { RequestTileList, LocationBlock },
  props: {
    transit: {
      type: Object,
      required: true
    }
  },
  methods: {
    attachedRequests () {
      return utils.requestList(this.transit)
    }
  }
}
</script>
