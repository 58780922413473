<template>
  <div>
    {{ method }} -
    {{ properties.skids }}
    SKD
    {{ properties.weight }}
    LBS
  </div>
</template>

<script>
export default {
  name: 'TransitProperties',
  props: {
    properties: {
      type: Object,
      required: true
    }
  },
  computed: {
    method () {
      switch (this.properties.method) {
        case 'ACT':
          return 'Actual'
        case 'EXP':
          return 'Expected'
        case 'EST':
          return 'Estimated'
        default:
          return 'Not Available'
      }
    }
  }
}
</script>
