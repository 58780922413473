<template>
  <unauthorized
    :authorized="$store.getters.can('view-transit')"
    message="Unauthorized to view Transit"
  >
    <app-loading-indicator
      v-if="isLoading"
    />
    <div v-else-if="transit">
      <div>
        <url-breadcrumbs
          :items="[
            ['Transit List', { name: 'transit-list' }],
            ['Transit #' + transit.id, { name: 'transit-view', params: { id: transit.id }}]
          ]"
        />
        <v-container fluid>
          <base-title>
            Outbound Transit #{{ transit.id }}
            <v-chip
              small
              color="primary"
            >
              {{ transit.status_display.toUpperCase() }}
            </v-chip>
          </base-title>
          <v-toolbar
            flat
            dense
          >
            <user-upload-dialog
              :file-links="fileLinks"
            />
            <v-spacer />
            <v-btn
              :disabled="!$store.getters.can('delete-transit')"
              small
              outlined
              @click="deleteTransit"
            >
              delete
            </v-btn>
            <v-btn
              class="ml-4"
              :disabled="!$store.getters.can('edit-transit')"
              small
              color="primary"
              @click="editDialog = !editDialog"
            >
              Edit
            </v-btn>
          </v-toolbar>
          <h3>
            Details
          </h3>
          <v-row>
            <v-col>
              <v-card>
                <v-card-text>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <strong>
                            Shipping Companies
                          </strong>
                        </v-list-item-title>
                        Broker:
                        <router-link-id
                          v-if="transit.broker_data"
                          name="broker-view"
                          :id="transit.broker_data.id"
                        >
                          {{ transit.broker_data.name }}
                        </router-link-id>
                        Carrier:
                        <router-link-id
                          v-if="transit.carrier_data"
                          name="carrier-view"
                          :id="transit.carrier_data.id"
                        >
                          {{ transit.carrier_data.name }}
                        </router-link-id>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <strong>
                            Quoted Rate
                          </strong>
                        </v-list-item-title>
                        {{ transit.rate_quote | currencyUSD }}
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <strong>
                            Actual Cost
                          </strong>
                        </v-list-item-title>
                        <fx-tag
                          :fx="transit.actual_cost_fx"
                          :value="transit.actual_cost"
                          :cur="transit.actual_cost_denomination"
                        />
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <strong>
                            Additional Fees
                          </strong>
                        </v-list-item-title>
                        <fx-tag
                          :fx="transit.additional_fees_fx"
                          :value="transit.additional_fees"
                          :cur="transit.additional_fees_denomination"
                        />
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <strong>
                            Lot Allocations <v-btn
                              text
                              small
                              @click="editTransitLots = !editTransitLots"
                            >{{ editTransitLots ? 'done' : 'edit' }}</v-btn>
                          </strong>
                        </v-list-item-title>
                        <transit-lot-edit
                          v-if="editTransitLots"
                          :transit="transit"
                          @change="fetchTransit"
                        />
                        <v-data-table
                          v-else
                          :items="transit.transitlot_set"
                          :headers="[
                            { text: 'Lot', value: 'lot_name' },
                            { text: 'Weight', value: 'weight', align: 'right' },
                            { text: 'Percent', value: 'allocation_percent', align: 'right' },
                            { text: 'Cost Value', value: 'allocation_value', align: 'right' },
                            { text: 'Fee Value', value: 'fees_value', align: 'right' }
                          ]"
                        >
                          <template v-slot:item.weight="{ item }">
                            {{ item.weight | formatNumber }} lbs
                          </template>
                          <template v-slot:item.allocation_percent="{ item }">
                            {{ item.allocation_percent * 100 }}%
                          </template>
                          <template v-slot:item.allocation_value="{ item }">
                            <fx-tag
                              :fx="item.allocation_value_fx"
                              :value="item.allocation_value"
                              :cur="item.allocation_value_currency"
                            />
                          </template>
                          <template v-slot:item.fees_value="{ item }">
                            <fx-tag
                              :fx="item.fees_value_fx"
                              :value="item.fees_value"
                              :cur="item.fees_value_currency"
                            />
                          </template>
                        </v-data-table>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <strong>
                            Fee/Rate Adjustment Notes
                          </strong>
                        </v-list-item-title>
                        {{ transit.fee_description }}
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <strong>
                            Shipped Date
                          </strong>
                        </v-list-item-title>
                        {{ transit.pickup_date | formatDate }}
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <strong>
                            Delivered Date
                          </strong>
                        </v-list-item-title>
                        {{ transit.delivery_date | formatDate }}
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="transit.dispute_notes"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          <strong>
                            Disputed notes
                          </strong>
                        </v-list-item-title>
                        {{ transit.dispute_notes }}
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="transit.notes"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          <strong>
                            Shipment Details
                          </strong>
                        </v-list-item-title>
                        {{ transit.shipment_details }}
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="transit.notes"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          <strong>
                            Notes
                          </strong>
                        </v-list-item-title>
                        {{ transit.notes }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <h3>
            Locations
          </h3>
          <v-row>
            <v-col>
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col
                      cols="6"
                    >
                      <h3>
                        Delivery From
                      </h3>
                      {{ transit.pickup_date | formatDate }}<em>Booked</em>
                      <location-block
                        class="mt-4"
                        :location="transit.from_address_details"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                    >
                      <h3>
                        Delivery To
                      </h3>
                      <span v-if="!transit.received_at">
                        {{ transit.delivery_date | formatDate }}<em>Estimated</em>
                      </span>
                      <span>
                        {{ transit.received_at | formatDate }}<em>Recieved</em>
                      </span>
                      <location-block
                        class="mt-4"
                        :location="transit.to_address_details"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <div v-if="transit.transit_type === 'OUT'">
            <h3>
              Lot Manifests
            </h3>
            <v-card>
              <v-card-text>
                <lot-manifest-detail-list
                  :lot-manifests="transit.lot_manifests_details"
                  append-icon="mdi-link"
                  @clicked="routeToLotManifest"
                />
              </v-card-text>
            </v-card>
            <div class="py-2" />
            <h3>
              Inventory Manifests
            </h3>
            <v-card>
              <v-card-text>
                <inventory-manifest-detail-list
                  :inventory-manifests="transit.inventorymanifest_set_details"
                  append-icon="mdi-link"
                  @clicked="routeToInventoryManifest"
                />
              </v-card-text>
            </v-card>
          </div>
        </v-container>
        <transit-outbound-edit-dialog
          v-model="editDialog"
          :transit="transit"
          @updated="transit = $event"
        />
      </div>
    </div>
    <div v-else>
      Failed to load transit
    </div>
  </unauthorized>
</template>

<script>
import LocationBlock from '@/views/Logistics/Transit/components/LocationBlock.vue'
import UserUploadDialog from '@/base/components/UserUploadDialog.vue'
import fileLinks from '@/helpers/fileLinks'
import TransitOutboundEditDialog from '@/views/Logistics/Transit/TransitOutboundEditDialog.vue'
import { fetchTransitById, deleteTransitById } from '@/requests/logistics'
import LotManifestDetailList from '@/views/Logistics/Transit/components/LotManifestDetailList.vue'
import TransitLotEdit from '@/views/Logistics/Transit/components/TransitLotEdit.vue'
import InventoryManifestDetailList from '@/views/Logistics/Transit/components/InventoryManifestDetailList.vue'

export default {
  name: 'TransitOutbound',
  components: { InventoryManifestDetailList, TransitLotEdit, LotManifestDetailList, TransitOutboundEditDialog, UserUploadDialog, LocationBlock },
  data: () => ({
    editTransitLots: false,
    transit: null,
    isLoading: false,
    editDialog: false
  }),
  computed: {
    fileLinks () {
      return fileLinks.transitFileLink(this.transit)
    }
  },
  methods: {
    deleteTransit () {
      if (confirm(`Are you sure you want to delete transit #${this.transit.id} ?`)) {
        deleteTransitById(this.transit.id).then(() => {
          this.$store.commit('setSnackbarSuccess', 'Transit deleted!')
          this.$router.push({ name: 'transit-dashboard' })
        })
      }
    },
    routeToLotManifest (manifest) {
      this.$router.push({ name: 'lot-manifest', params: { id: manifest.id } })
    },
    routeToInventoryManifest (manifest) {
      this.$router.push({ name: 'manifest-view', params: { id: manifest.id } })
    },
    fetchTransit () {
      return fetchTransitById(this.$route.params.id).then(res => {
        if (res.data) this.transit = res.data
      })
    }
  },
  mounted () {
    this.isLoading = true
    this.fetchTransit().finally(() => {
      this.isLoading = false
    })
  }
}
</script>
