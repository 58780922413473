<template>
  <div v-if="transit">
    <ValidationObserver
      v-slot="{ invalid }"
      ref="validationObserver"
    >
      <edit-dialog
        :value="value"
        :inputs-valid="!invalid"
        :headline-text="`Edit Transit #${transit.id}`"
        @input="$emit('input', $event)"
        @accept="accept"
      >
        <edit-transit-toolbar
          class="pa-2 ma-0"
          @statusUpdate="status = $event"
        />
        <v-row>
          <v-col>
            <h2>
              {{ transit.name }}
              <v-chip
                small
                :color="transit.color"
              >
                {{ transit.status_display }}
              </v-chip>
              <info-tooltip>
                Broker / Carrier
              </info-tooltip>
            </h2>
            <p>
              Booked By: {{ transit.booked_by__username }} on {{ transit.booked_at | formatDate }}
              <br>
              <span
                v-if="$store.getters.can('view-rate-quote')"
              >
                Quoted Rate: {{ transit.rate_quote | currencyUSD }}
              </span>
            </p>
            <div
              v-if="disputeNotes.length"
            >
              <h4>
                Dispute Notes
              </h4>
              <p class="red--text darken-2">
                {{ disputeNotes }}
              </p>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-form
              :disabled="!$store.getters.can('edit-transit')"
            >
              <date-range-input
                :start-date="pickupDate"
                :end-date="deliveryDate"
                start-date-label="Pick Up Date*"
                end-date-label="Delivery Date*"
                @alterStartDate="pickupDate = $event"
                @alterEndDate="deliveryDate = $event"
              />
              <v-row>
                <v-col
                  cols="6"
                >
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-select
                      v-model="fromLocation"
                      item-text="name"
                      return-object
                      label="Origin*"
                      hint="Origins are based on requests"
                      :items="getOriginOptions"
                      :error-messages="errors"
                    />
                    <location-block
                      v-if="fromLocation"
                      :location="fromLocation"
                    />
                  </ValidationProvider>
                  <v-checkbox
                    :disabled="transit.status !== 'NE'"
                    v-model="carrierPickedUp"
                    label="Carrier picked up?"
                  />
                </v-col>
                <v-col cols="6">
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-select
                      v-model="toLocation"
                      item-text="name"
                      return-object
                      label="Destination*"
                      :items="$store.getters['transitDashboardStore/getDestinationOptions']"
                      :error-messages="errors"
                    />
                    <location-block
                      v-if="toLocation"
                      :location="toLocation"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <!-- Document References -->
        <h4>Document References</h4>
        <v-row>
          <v-col class="text-right">
            <document-reference-editor
              reference-model="transit"
              :entity-id="transit.id"
              @update:references="transit.references = $event"
            />
          </v-col>
        </v-row>
        <v-col>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Reference Type
                  </th>
                  <th class="text-left">
                    Reference ID
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(reference, index) in transit.references"
                  :key="index"
                >
                  <td>{{ reference.ref_type_name }}</td>
                  <td>{{ reference.ref }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <h4>Requests</h4>
        <v-row>
          <v-toolbar
            flat
            dense
          >
            <v-spacer />
            <attach-requests />
          </v-toolbar>
        </v-row>
        <v-row>
          <v-col>
            <p>
              Click a Request to detach from transit.
            </p>
            <v-progress-linear
              v-if="!ready"
              indeterminate
              color="accent"
            />
            <request-tile-list
              :disabled="!ready"
              :request-list="attachedRequests"
              @click="openAttachedMenu"
            />
          </v-col>
        </v-row>
      </edit-dialog>
      <edit-dialog
        v-model="attachedMenuDialog"
        headline-text="Select Action"
        accept-text="done"
        :show-decline="false"
      >
        <v-list
          v-if="attachedSelected"
        >
          <v-list-item
            :disabled="!$store.getters.can('edit-transit') || $store.getters['transitDashboardStore/editTransit/attachmentsLocked']"
            class="mb-2 elevation-1"
            @click="detachRequest"
          >
            <v-list-item-avatar>
              <v-icon>
                mdi-playlist-remove
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              Detach Request from Transit
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="attachedSelected.type === 'MANIFEST'"
            :disabled="!$store.getters.can('view-manifests')"
            target="_blank"
            :to="{ name: 'manifest-print', params: { id: attachedSelected.id }}"
          >
            <v-list-item-avatar>
              <v-icon>
                mdi-printer
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              Open Manifest Print
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </edit-dialog>
    </validationobserver>
  </div>
</template>

<script>
import LocationBlock from './LocationBlock'
import EditTransitToolbar from '@/views/Logistics/Transit/EditTransitToolbar'
import RequestTileList from '@/views/Logistics/Transit/components/RequestTileList'
import utils from '@/helpers/transitUtils'
import AttachRequests from '@/views/Logistics/Transit/components/AttachRequests'
import DocumentReferenceEditor from '@/base/components/DocumentReferenceEditor'

export default {
  name: 'EditTransit',
  components: {
    AttachRequests,
    RequestTileList,
    EditTransitToolbar,
    LocationBlock,
    DocumentReferenceEditor
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  // Once they've clicked/checked it, transition status
  // Should only appear unchecked when new
  // When clicked change status to IT
  // Once Received, hide button
  data: () => ({
    disputeNotes: '',
    status: null,
    pickupDate: '',
    deliveryDate: '',
    fromLocation: null,
    toLocation: null,
    ready: true,
    attachedMenuDialog: false,
    attachedSelected: null,
    markedInTransit: false
  }),
  watch: {
    transit (transit) {
      if (transit) {
        this.status = transit.status
        this.disputeNotes = transit.dispute_notes
        this.pickupDate = transit.pickup_date
        this.deliveryDate = transit.delivery_date
        this.fromLocation = transit.from_address_details
        this.toLocation = transit.to_address_details
        this.markedInTransit = false
      }
    }
  },
  computed: {
    // gets the transit currently being edited
    transit () {
      return this.$store.getters['transitDashboardStore/editTransit/transit']
    },
    // Combines attached manifests and checkins into single list
    attachedRequests () {
      return utils.requestList(this.transit)
    },
    // List of Possible Transit Origins available based on attached locations adding current origin
    getOriginOptions () {
      let origins = this.$store.getters['transitDashboardStore/editTransit/getOriginOptions']
      if (this.fromLocation && !origins.find(o => o.id === this.fromLocation.id)) {
        origins.push(this.fromLocation)
      }
      return origins
    },
    carrierPickedUp: {
      get () {
        return !(this.transit.status === 'NE' && !this.markedInTransit)
      },
      set (checked) {
        if (checked) this.markedInTransit = true
      }
    }
  },
  methods: {
    /**
     * Opens a dialog that allows additional operations
     */
    openAttachedMenu (request) {
      this.attachedSelected = request
      this.attachedMenuDialog = true
    },
    /**
     * Removes a request from the currently edited transit
     */
    async detachRequest () {
      const request = this.attachedSelected
      this.attachedSelected = null
      this.attachedMenuDialog = false

      if (this.$store.getters['transitDashboardStore/editTransit/attachmentsLocked']) {
        this.$store.commit('setSnackbarWarning', 'unable to change attachments given current status')
        return
      }
      if (!this.$store.getters.can('edit-transit')) {
        this.$store.commit('setSnackbarInfo', 'unauthorized to detach request')
        return
      }
      if (confirm(
        `Are you sure you would like to detach ${request.type} #${request.id}?
        ${this.transit.status === 'IT' ? 'NOTE:\nThe check-in\'s current location will be set to the transit\'s origin' : ''}
        `
      )) {
        this.ready = false
        // PATCH transit with detached request.
        await this.$store.dispatch('transitDashboardStore/editTransit/patchTransit', {
          detach_requests: [request]
        }).then(() => {
          this.$store.dispatch('transitDashboardStore/requests/fetchTransitRequests')
        })
        this.ready = true
      }
    },
    /**
     * Ability to close out a transit entry as received
     */
    receiveTransit () {
      this.$store.dispatch('transitDashboardStore/receiveTransit', {
        transit: this.transit,
        checkIns: this.checkIns
      })
      this.$store.commit('transitDashboardStore/editTransit/setTransit', null)
    },
    accept () {
      const transitUrl = this.transit.url
      const payload = {
        status: this.status,
        dispute_notes: this.disputeNotes,
        from_address: this.fromLocation.url,
        pickup_date: this.pickupDate,
        delivery_date: this.deliveryDate,
        to_address: this.toLocation.url
      }
      if (this.markedInTransit && this.status === 'NE') {
        payload.status = 'IT'
      }
      this.$store.dispatch('transitDashboardStore/patchTransit', { transitUrl, payload })
      this.$store.commit('transitDashboardStore/editTransit/setTransit', null)
    }
  }
}
</script>
