<template>
  <unauthorized
    :authorized="$store.getters.can('view-transit')"
    message="Unauthorized to view Transit"
  >
    <transit-inbound
      v-if="view === 'IN'"
    />
    <transit-outbound
      v-else-if="view === 'OUT'"
    />
    <app-loading-indicator
      v-else-if="loading"
    />
  </unauthorized>
</template>

<script>
import axios from 'axios'
import TransitInbound from '@/views/Logistics/Transit/TransitInbound.vue'
import TransitOutbound from '@/views/Logistics/Transit/TransitOutbound.vue'

export default {
  name: 'TransitView',
  components: { TransitOutbound, TransitInbound },
  data: () => ({
    view: null,
    loading: false
  }),
  mounted () {
    this.loading = true
    axios.get(`/logistics/transits/${this.$route.params.id}/?fields=transit_type`).then(res => {
      if (res.data.transit_type) {
        this.view = res.data.transit_type
      }
    }).finally(() => {
      this.loading = false
    })
  }
}
</script>
