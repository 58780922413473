<!--
Transit Dashboard that show sections for managing entries
Freight Requests
Booked transits
Received Transits
-->
<template>
  <unauthorized
    :authorized="$store.getters.can('view-transit')"
    message="You are not authorized to view transit"
  >
    <base-title title="Transit Dashboard" />
    <v-toolbar
      flat
      dense
    >
      <v-btn
        small
        :to="{ name: 'transit-merge' }"
      >
        Merge Transits
      </v-btn>
      <v-spacer />
      <request-transit>
        <template
          v-slot:activator="{ on }"
        >
          <v-btn
            class="mx-2"
            :disabled="!$store.getters.can('request-transit')"
            color="primary"
            small
            @click="on"
          >
            Req CheckIn Transit
          </v-btn>
        </template>
      </request-transit>
      <create-transit>
        <template
          v-slot:activator="{ on }"
        >
          <v-btn
            :disabled="!$store.getters.can('create-transit')"
            color="primary"
            small
            @click="on"
          >
            Create Transit
          </v-btn>
        </template>
      </create-transit>
    </v-toolbar>
    <v-row>
      <v-col
        cols="12"
        sm="6"
      >
        <v-select
          style="max-width: 400px"
          dense
          multiple
          chips
          deletable-chips
          label="Destination"
          item-text="name"
          item-value="url"
          v-model="destination"
          :items="$store.getters['transitDashboardStore/getDestinationOptions']"
        />
      </v-col>
      <v-col
        cols="12"
        offset-sm="3"
        sm="3"
      >
        <v-spacer />
        <v-text-field
          label="Filter"
          v-model="filterText"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        offset="1"
        cols="10"
        offset-sm="0"
        sm="4"
        class="pa-0"
      >
        <v-sheet
          class="pa-2 ma-2"
        >
          <freight-requests />
        </v-sheet>
      </v-col>
      <v-col
        style="border-right: 2px solid lightgray; border-left: 2px solid lightgray"
        offset="1"
        cols="10"
        offset-sm="0"
        sm="4"
        class="pa-0"
      >
        <v-sheet
          class="pa-2 ma-2"
        >
          <booked-transits />
        </v-sheet>
      </v-col>
      <v-col
        offset="1"
        cols="10"
        offset-sm="0"
        sm="4"
        class="pa-0"
      >
        <v-sheet
          class="pa-2 ma-2"
        >
          <received-transits />
        </v-sheet>
      </v-col>
    </v-row>
  </unauthorized>
</template>

<script>
import CreateTransit from '@/views/Logistics/Transit/components/CreateTransit'
import RequestTransit from '@/views/Logistics/Transit/components/RequestTransit'
import FreightRequests from '@/views/Logistics/FreightRequests'
import BookedTransits from '@/views/Logistics/BookedTransits'
import ReceivedTransits from '@/views/Logistics/ReceivedTransits'

export default {
  name: 'TransitDashboard',
  components: {
    ReceivedTransits,
    BookedTransits,
    FreightRequests,
    RequestTransit,
    CreateTransit
  },
  data: () => ({
    requestEditDialog: false,
    transitEditDialog: false,
    transitReceivedEditDialog: false
  }),
  computed: {
    filterText: {
      get () {
        return this.$store.getters['transitDashboardStore/getFilter']
      },
      set (value) {
        this.$store.commit('transitDashboardStore/setFilter', value)
      }
    },
    destination: {
      get () {
        return this.$store.getters['transitDashboardStore/getDestinationFilter']
      },
      set (value) {
        this.$store.commit('transitDashboardStore/setDestinationFilter', value)
      }
    }
  },
  mounted () {
    this.$store.dispatch('transitDashboardStore/requests/fetchTransitRequests')
    this.$store.dispatch('transitDashboardStore/fetchTransits')
    this.$store.dispatch('transitDashboardStore/fetchDivisionDestinations')
  }
}
</script>
