<template>
  <div>
    <slot
      name="activator"
      :on="() => dialog = !dialog"
    />
    <ValidationObserver
      v-slot="{ invalid }"
      ref="validationObserver"
    >
      <edit-dialog
        v-model="dialog"
        :inputs-valid="fieldsValid && !invalid"
        headline-text="Create Transit"
        @accept="accept"
      >
        <v-row>
          <v-col
            offset="1"
            cols="10"
            offset-md="2"
            md="8"
          >
            <v-form
              :disabled="!$store.getters.can('create-transit')"
            >
              <date-range-input
                :start-date="pickupDate"
                :end-date="deliveryDate"
                start-date-label="Pick Up Date*"
                end-date-label="Delivery Date*"
                @alterStartDate="pickupDate = $event"
                @alterEndDate="deliveryDate = $event"
              />
              <v-row>
                <v-col
                  cols="6"
                >
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-select
                      v-model="fromLocation"
                      item-text="name"
                      return-object
                      label="Origin*"
                      hint="Origins are based on requests"
                      :items="getOriginOptions"
                      :error-messages="errors"
                      @change="fetchHistory"
                    />
                    <location-block
                      v-if="fromLocation"
                      :location="fromLocation"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="6">
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-select
                      v-model="toLocation"
                      item-text="name"
                      return-object
                      label="Destination*"
                      :items="getDestinationOptions"
                      :error-messages="errors"
                      @change="fetchHistory"
                    />
                    <location-block
                      v-if="toLocation"
                      :location="toLocation"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <shipping-cost-history-table
                    v-if="fromLocation && toLocation"
                    :from-location="fromLocation"
                    :to-location="toLocation"
                  />
                  <div v-else>
                    <em class="subtitle-2">
                      Historic Average displayed when origin an destination selected.
                    </em>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <ValidationProvider
                    rules="two_decimal"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      prepend-inner-icon="mdi-currency-usd"
                      label="Rate Quote"
                      type="number"
                      v-model="rateQuote"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    label="Broker"
                    v-model="broker"
                    item-text="name"
                    :items="brokerOptions"
                    clearable
                    return-object
                  />
                  <p class="text-right">
                    <create-broker-dialog
                      @created="setBroker"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-if="$store.getters.can('create-broker')"
                          :disabled="!!broker"
                          text
                          @click="on"
                        >
                          New Broker
                        </v-btn>
                      </template>
                    </create-broker-dialog>
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    required
                    label="Carrier*"
                    v-model="carrier"
                    item-text="name"
                    :items="carrierOptions"
                    clearable
                    return-object
                  />
                  <p class="text-right">
                    <create-carrier-dialog
                      :minimal-fields="true"
                      @created="setCarrier"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-if="$store.getters.can('create-carrier')"
                          :disabled="!!carrier"
                          text
                          @click="on"
                        >
                          New Carrier
                        </v-btn>
                      </template>
                    </create-carrier-dialog>
                  </p>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </edit-dialog>
    </validationobserver>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LocationBlock from './LocationBlock'
import CreateCarrierDialog from '@/views/Carriers/component/CreateCarrierDialog'
import axios from 'axios'
import CreateBrokerDialog from '@/views/Brokers/component/CreateBrokerDialog'
import ShippingCostHistoryTable from '@/components/Transit/ShippingCostHistoryTable.vue'

export default {
  name: 'CreateTransit',
  components: { ShippingCostHistoryTable, CreateBrokerDialog, CreateCarrierDialog, LocationBlock },
  data: () => ({
    brokerOptions: [],
    carrierOptions: [],
    broker: null,
    carrier: null,
    rateQuote: 0,
    dialog: false,
    pickupDate: '',
    deliveryDate: '',
    fromLocation: null,
    toLocation: null,
    historicAvg: null,
    history: []
  }),
  computed: {
    ...mapGetters('transitDashboardStore/requests', [
      'getRequested',
      'getOriginOptions'
    ]),
    ...mapGetters('transitDashboardStore', [
      'getDestinationOptions'
    ]),
    fieldsValid () {
      return Boolean(this.pickupDate && this.deliveryDate && this.carrier && this.toLocation && this.fromLocation)
    }
  },
  watch: {
    dialog (opened) {
      if (!opened) return
      this.fetchCarriers()
      this.fetchBrokers()
    }
  },
  methods: {
    clearFields () {
      this.broker = null
      this.carrier = null
      this.rateQuote = 0
      this.dialog = false
      this.pickupDate = ''
      this.deliveryDate = ''
      this.fromLocation = null
      this.toLocation = null
      this.historicAvg = null
      this.history = []
    },
    ...mapActions('transitDashboardStore', [
      'createTransit'
    ]),
    // For setting a broker directly
    setBroker (broker) {
      this.brokerOptions.push(broker)
      this.broker = broker
    },
    // For setting a carrier directly
    setCarrier (carrier) {
      this.carrierOptions.push(carrier)
      this.carrier = carrier
    },
    accept () {
      if (!this.carrier) {
        this.$store.commit('setSnackbarWarning', 'Carrier is required')
        return
      }
      let transit = {
        'from_address': this.fromLocation.url,
        'pickup_date': this.pickupDate,
        'delivery_date': this.deliveryDate,
        'to_address': this.toLocation.url,
        'carrier': this.carrier.url,
        'rate_quote': Math.round(this.rateQuote * 100) / 100,
        'transitfile_set': [],
        'lot_manifests': []
      }
      if (this.broker) transit.broker = this.broker.url
      this.createTransit(transit).then(res => {
        this.dialog = false
        this.clearFields()
        if (res.data) {
          this.$store.dispatch('transitStore/dispatchTransitEmail', res.data.id)
        }
      })
    },
    // Does an api request to get all required carriers
    fetchCarriers () {
      axios.get('/logistics/carriers/?page_size=0&status=GS&fields=url,name').then(r => {
        if (r.status === 200) this.carrierOptions = r.data
      })
    },
    // Does an api request to get all required carriers
    fetchBrokers () {
      axios.get('/logistics/brokers/?page_size=0&status=GS&fields=url,name').then(r => {
        if (r.status === 200) this.brokerOptions = r.data
      })
    },
    /**
     * Fetches a actual cost history based on locations
     * @return {Promise<void>}
     */
    async fetchHistory () {
      if (!this.fromLocation) return
      if (!this.toLocation) return
      const params = { from_address: this.fromLocation.id, to_address: this.toLocation.id }
      const r = await axios.get('/logistics/transits/rate_history/', { params })
      if (r.status !== 200) return
      this.historicAvg = r.data.actual_cost__avg
      this.history = r.data.history
    }
  }
}
</script>
