<template>
  <unauthorized
    :authorized="$store.getters.can('view-broker')"
    message="Unauthorized to view Broker"
  >
    <div v-if="entry">
      <url-breadcrumbs
        class="d-print-none"
        :items="[
          ['Manifest List', { name: 'manifest-list' }],
          ['Manifest #' + entry.id, { name: 'manifest-view', params: { id: entry.id }}]
        ]"
      />
      <v-container fluid>
        <base-title>
          Manifest #{{ entry.id }}
        </base-title>
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-img
              :src="config.logo"
              :alt="config.name"
              height="100px"
              contain
            />
          </v-col>
          <v-col
            cols="12"
            md="8"
          >
            <v-simple-table
              dense
            >
              <tbody>
                <tr
                  v-for="(row, index) in manifestSummary"
                  :key="index"
                >
                  <td>{{ row[0] }}</td>
                  <td>{{ row[1] }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-card flat>
          <v-card-text>
            <boxes-snapshot
              :snapshot="this.entry.inventory_box_snapshot"
            />
          </v-card-text>
        </v-card>
      </v-container>
    </div>
  </unauthorized>
</template>

<script>
import axios from 'axios'
import utils from '@/helpers/utils'
import BoxesSnapshot from '@/views/Manifest/components/BoxesSnapshot'
import config from '@/../izzi_config'

export default {
  name: 'ManifestPrint',
  components: { BoxesSnapshot },
  data: () => ({
    config,
    transit: null
  }),
  computed: {
    manifestSummary () {
      if (!this.transit) return
      const t = this.entry.current_location__name + ' to ' + this.entry.to_location__name
      return [
        ['Inter-company Transfer', t],
        ['Date', utils.formatDate(this.transit.delivery_date)],
        ['Transit Details', this.transit.name],
        ['SEAL', this.entry.seal_number],
        ['Remarks', this.entry.remarks]
      ]
    },
    entry () {
      return this.$store.state['inventoryManifestStore'].items[this.$route.params.id]
    }
  },
  methods: {
    /**
     * Fetches the manifest transit object if it exists
     * @return {Promise<void>}
     */
    async fetchTransit () {
      if (!this.entry) {
        this.$store.commit('setSnackbarWarning', 'Tried to load Transit without a Manifest.')
        return
      }
      if (!this.entry.transit) return
      const res = await axios.get(this.entry.transit)
      if (res.status === 200) this.transit = res.data
    }
  },
  mounted () {
    this.$store.dispatch('inventoryManifestStore/fetchInventoryManifest', this.$route.params.id).then(() => {
      this.fetchTransit()
    })
  }
}
</script>
