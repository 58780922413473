<template>
  <div>
    <slot
      name="activator"
      :on="() => dialog = !dialog"
    />
    <ValidationObserver
      v-slot="{ invalid }"
      ref="validationObserver"
    >
      <edit-dialog
        v-model="dialog"
        :inputs-valid="!invalid"
        headline-text="Request Transit"
        @accept="accept"
      >
        <v-row>
          <v-col
            offset="1"
            cols="10"
            offset-md="2"
            md="8"
          >
            <v-form
              :disabled="!$store.getters.can('create-transit')"
            >
              <v-row>
                <v-col>
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <search-autocomplete
                      v-model="checkIn"
                      label="CheckIn ID Search"
                      hint="type to start searching"
                      placeholder="Start typing to Search"
                      api-path="/api/check_ins/"
                      :format-entry="formatCheckIn"
                    />
                    <div class="text-center">
                      Type to start searching for a check in.
                    </div>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-select
                      v-model="toLocation"
                      item-text="name"
                      return-object
                      label="Destination*"
                      :items="getDestinationOptions"
                      :error-messages="errors"
                    />
                    <location-block
                      v-if="toLocation"
                      :location="toLocation"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </edit-dialog>
    </validationobserver>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LocationBlock from './LocationBlock'
import SearchAutocomplete from '@/base/components/SearchAutocomplete'

export default {
  name: 'RequestTransit',
  components: { SearchAutocomplete, LocationBlock },
  data: () => ({
    dialog: false,
    toLocation: null,
    checkIn: null
  }),
  computed: {
    ...mapGetters('transitDashboardStore', [
      'getDestinationOptions'
    ])
  },
  methods: {
    ...mapActions('transitDashboardStore/requests', [
      'createCheckInRequest'
    ]),
    formatCheckIn (checkIn) {
      return Object.assign({
        friendlyName: `${checkIn.id} ${checkIn.yard_name}`
      }, checkIn)
    },
    accept () {
      if (!this.checkIn) return
      // const payload = {
      //   'to_address': this.toLocation.url,
      //   'requires_transit': true
      // }

      const checkInUrl = this.checkIn.url
      const toLocationUrl = this.toLocation.url

      this.createCheckInRequest({ checkInUrl, toLocationUrl }).then(() => {
        this.dialog = false
      })
      // this.patchCheckInRequestTransit({ checkInUrl, payload }).then(() => {
      //   this.dialog = false
      // })
    }
  }
}
</script>
