<template>
  <v-card>
    <v-card-title>
      Shipping Cost History
    </v-card-title>
    <v-data-table
      v-if="history.length"
      :headers="[
        { text: 'Transit Id', value: 'id' },
        { text: 'Actual Cost', value: 'actual_cost' },
        { text: 'Booked On', value: 'booked_at' }
      ]"
      :items="history"
    >
      <template v-slot:item.actual_cost="{ item }">
        {{ item.actual_cost | currencyUSD }}
      </template>
      <template v-slot:item.booked_at="{ item }">
        {{ item.booked_at | formatDate }}
      </template>
      <template v-slot:body.append>
        <tr>
          <td class="font-weight-bold">
            Average
          </td>
          <td class="font-weight-bold">
            {{ historicAvg | currencyUSD }}
          </td>
          <td />
        </tr>
      </template>
    </v-data-table>
    <v-card-text v-else>
      No history to show
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ShippingCostHistoryTable',
  props: {
    fromLocation: {
      type: Object,
      default: null
    },
    toLocation: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    historicAvg: null,
    history: []
  }),
  watch: {
    fromLocation () {
      this.fetchHistory()
    },
    toLocation () {
      this.fetchHistory()
    }
  },
  methods: {
    /**
     * Fetches a actual cost history based on locations
     * @return {Promise<void>}
     */
    async fetchHistory () {
      if (!this.fromLocation) return
      if (!this.toLocation) return
      const params = { from_address: this.fromLocation.id, to_address: this.toLocation.id }
      const r = await axios.get('/logistics/transits/rate_history/', { params })
      if (r.status !== 200) return
      this.historicAvg = r.data.actual_cost__avg
      this.history = r.data.history
    }
  },
  mounted () {
    this.fetchHistory()
  }
}
</script>
