<template>
  <div>
    <slot
      name="activator"
      :on="() => dialog = !dialog"
    >
      <v-btn
        :disabled="this.$store.getters['transitDashboardStore/editTransit/attachmentsLocked']"
        small
        color="primary"
        @click="dialog = !dialog"
      >
        Attach Requests
      </v-btn>
    </slot>
    <edit-dialog
      headline-text="Attach Requests"
      v-model="dialog"
      :show-decline="false"
      accept-text="Done"
    >
      <v-card-text>
        <v-progress-linear
          v-show="!ready"
          indeterminate
          color="accent"
        />
        <v-checkbox
          label="Matching Destination"
          v-model="onlyMatchingDestination"
        />
        <v-list>
          <request-tile
            v-for="(request, index) in $store.getters['transitDashboardStore/requests/getRequested']"
            :key="index"
            :request="request"
            v-show="showRequest(request)"
            @click="attachRequest(request)"
            :disabled="!ready"
          >
            <v-row>
              <v-col>
                <transit-properties
                  v-if="request.transit_properties"
                  :properties="request.transit_properties"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <location-block
                  v-if="request.current_location"
                  :location="request.current_location"
                />
              </v-col>
              <v-col class="text-right">
                <location-block
                  v-if="request.to_location"
                  :location="request.to_location"
                />
              </v-col>
            </v-row>
          </request-tile>
        </v-list>
      </v-card-text>
    </edit-dialog>
  </div>
</template>

<script>
import LocationBlock from '@/views/Logistics/Transit/components/LocationBlock'
import TransitProperties from '@/views/Logistics/Transit/components/TransitProperties'
import RequestTile from '@/views/Logistics/Transit/components/RequestTile'

export default {
  name: 'AttachRequests',
  components: { RequestTile, TransitProperties, LocationBlock },
  data: () => ({
    dialog: false,
    onlyMatchingDestination: true,
    ready: true
  }),
  methods: {
    /**
     * Adds a request to the current edit transit
     */
    async attachRequest (request) {
      if (this.$store.getters['transitDashboardStore/editTransit/attachmentsLocked']) {
        this.$store.commit('setSnackbarWarning', 'unable to change attachments given current status')
        return
      }
      if (!this.$store.getters.can('edit-transit')) {
        this.$store.commit('setSnackbarInfo', 'unauthorized to attach request')
        return
      }
      this.ready = false
      await this.$store.dispatch('transitDashboardStore/editTransit/patchTransit', {
        attach_requests: [request]
      })
      this.ready = true
    },
    /**
     * Determine if the checkin should be shown
     */
    showRequest (request) {
      if (!this.onlyMatchingDestination) return true
      const toAddress = this.$store.getters['transitDashboardStore/editTransit/transit'].to_address
      if (!toAddress) return true
      if (!request.to_location) return true
      return request.to_location.url === toAddress
    }
  }
}
</script>
