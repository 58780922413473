<template>
  <parent-tabs :tabs="filterTabs()" />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TransitParent',
  methods: {
    filterTabs () {
      return this.pageTabs.reduce((accu, i) => {
        if (i.can) accu.push(i)
        return accu
      }, [])
    }
  },
  computed: {
    ...mapGetters({
      can: 'can'
    }),
    pageTabs () {
      return [
        { id: 1, title: 'Dashboard', route: 'dashboard', can: this.can('view-transit') },
        { id: 2, title: 'Inbound', route: 'transit-list', can: this.can('view-transit') },
        { id: 3, title: 'Outbound', route: 'outbound-list', can: this.can('view-transit') },
        { id: 4, title: 'Inventory Manifests', route: 'manifest-list', can: this.can('view-manifests') },
        { id: 5, title: 'Lot Manifests', route: 'lot-manifest-list', can: this.can('view-lot-manifests') }
        // { id: 6, title: 'Carriers', route: 'carrier-list', can: this.can('view-carrier') },
        // { id: 7, title: 'Brokers', route: 'broker-list', can: this.can('view-broker') }
      ]
    }
  }
}
</script>
