var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('unauthorized',{attrs:{"authorized":_vm.$store.getters.can('view-inventory-on-hand'),"message":"Unauthorized to view Inventory on Hand"}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.save($event)}}},[_c('v-container',{staticClass:"mb-16",attrs:{"fluid":""}},[_c('url-breadcrumbs',{attrs:{"items":[
            ['Inventory Manifest List', { name: 'manifest-list' }],
            ['Manifest Create', { name: 'manifest-create' }]
          ]}}),_c('base-title',{attrs:{"title":"Create Manifest"}}),_c('v-card',{staticClass:"mt-4"},[_c('v-card-title',[_vm._v(" Details ")]),_c('v-card-text',[_c('rules-date',{attrs:{"label":"Request Pick Up Date","validation-rules":"required"},model:{value:(_vm.pickUpDate),callback:function ($$v) {_vm.pickUpDate=$$v},expression:"pickUpDate"}}),_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Remarks","counter":"","auto-grow":"","error-messages":errors},model:{value:(_vm.remarks),callback:function ($$v) {_vm.remarks=$$v},expression:"remarks"}})]}}],null,true)})],1),_c('v-card-title',[_vm._v(" Shipping Origin ")]),_c('v-card-text',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Inventory Location (Inventory Location Select)","clearable":"","items":_vm.inventoryLocations,"item-text":"name","item-value":"url","error-messages":errors},model:{value:(_vm.originUrl),callback:function ($$v) {_vm.originUrl=$$v},expression:"originUrl"}})]}}],null,true)})],1),_c('v-card-title',[_vm._v(" Shipping Destination ")]),_c('v-card-actions',[_c('v-switch',{attrs:{"label":"Use Location Search","dense":""},model:{value:(_vm.useDestinationSearch),callback:function ($$v) {_vm.useDestinationSearch=$$v},expression:"useDestinationSearch"}})],1),_c('v-card-text',[(!_vm.useDestinationSearch)?_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Inventory Destination (Inventory Location Select)","items":_vm.inventoryLocations,"item-text":"name","item-value":"url","error-messages":errors},model:{value:(_vm.destinationUrl),callback:function ($$v) {_vm.destinationUrl=$$v},expression:"destinationUrl"}})]}}],null,true)}):_c('div',[_c('search-autocomplete',{attrs:{"label":"Inventory Destination (Location Search)","api-path":"/logistics/locations/","format-entry":_vm.formatName},model:{value:(_vm.destinationSearched),callback:function ($$v) {_vm.destinationSearched=$$v},expression:"destinationSearched"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.destinationError),expression:"destinationError"}],staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.destinationError)+" ")])],1),_c('em',[_vm._v(" Box selection is available after the manifest is created. ")]),_c('v-checkbox',{attrs:{"label":"Requires Transit? (sends email to logistics team)"},model:{value:(_vm.requiresTransit),callback:function ($$v) {_vm.requiresTransit=$$v},expression:"requiresTransit"}})],1)],1),_c('text-fab',{attrs:{"disabled":invalid || !!_vm.destinationError,"bottom":"","right":"","fixed":"","icon-text":"mdi-check","color":"primary","type":"submit"}},[_vm._v(" Save ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }