<!--
Form fields for creating a Broker
@created(broker) that is created
-->
<template>
  <div>
    <slot
      name="activator"
      :on="() => dialog = !dialog"
    >
      <v-btn
        @click="dialog = !dialog"
      >
        Create Converter
      </v-btn>
    </slot>
    <ValidationObserver
      v-slot="{ invalid }"
      ref="validationObserver"
    >
      <edit-dialog
        headline-text="Create Broker"
        v-model="dialog"
        :inputs-valid="!invalid"
        @accept="createBroker"
      >
        <v-row
          v-if="minimalFields"
        >
          <v-col>
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
            >
              <v-text-field
                label="Name*"
                v-model="name"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <div
          v-if="!minimalFields"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <ValidationProvider
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  label="Name*"
                  v-model="name"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <ValidationProvider
                rules="max:40"
                v-slot="{ errors }"
              >
                <v-text-field
                  label="Contact"
                  v-model="contactName"
                  :error-messages="errors"
                />
              </validationprovider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <ValidationProvider
                rules=""
                v-slot="{ errors }"
              >
                <v-text-field
                  label="Website"
                  v-model="website"
                  :error-messages="errors"
                />
              </validationprovider>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <ValidationProvider
                rules="max:40"
                v-slot="{ errors }"
              >
                <v-text-field
                  label="Phone"
                  v-model="phoneNumber"
                  :error-messages="errors"
                />
              </validationprovider>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <ValidationProvider
                rules=""
                v-slot="{ errors }"
              >
                <v-text-field
                  label="Email"
                  v-model="email"
                  :error-messages="errors"
                />
              </validationprovider>
            </v-col>
          </v-row>
        </div>
      </edit-dialog>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  name: 'CreateBrokerDialog',
  props: {
    minimalFields: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dialog: false,
    name: '',
    website: '',
    phoneNumber: '',
    contactName: '',
    email: ''
  }),
  methods: {
    async createBroker () {
      if (!this.name) {
        this.$store.commit('setSnackbarError', 'Broker name is required')
        return
      }
      const payload = {
        name: this.name,
        website: this.website,
        phone_number: this.phoneNumber,
        contact_name: this.contactName,
        email: this.email
      }
      const response = await this.$store.dispatch('brokerStore/createBroker', payload)
      if (response.status === 201) {
        this.$emit('created', response.data)
        this.$store.commit('setSnackbarInfo', `New Broker Created #${response.data.id} ${response.data.name}`)
      }
    }
  }
}
</script>
