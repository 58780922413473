<!--
Booked Transit Card
-->
<template>
  <v-card
    class="my-1"
    @click="$emit('click')"
  >
    <v-card-title class="subtitle-2">
      {{ transit.name }}
      <v-chip
        class="mx-2"
        small
        :color="transit.color"
      >
        {{ transit.status_display }}
      </v-chip>
    </v-card-title>
    <v-card-subtitle class="text-sm-subtitle-2">
      <div v-if="transit.from_address_details">
        From:
        <info-tooltip>
          <template v-slot:wrap-me>
            {{ transit.from_address_details.name }}
          </template>
          <location-block :location="transit.from_address_details" />
        </info-tooltip>
      </div>
      <div v-if="transit.to_address_details">
        To:
        <info-tooltip>
          <template v-slot:wrap-me>
            {{ transit.to_address_details.name }}
          </template>
          <location-block :location="transit.to_address_details" />
        </info-tooltip>
      </div>
      {{ transit.delivery_date | formatDate }}<info-tooltip>Delivery Date</info-tooltip>
    </v-card-subtitle>
    <v-card-text>
      <div
        v-if="transit.dispute_notes"
        class="red--text"
      >
        {{ transit.dispute_notes }}
      </div>
      <request-tile-list
        :request-list="attachedRequests()"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import LocationBlock from './LocationBlock'
import RequestTileList from '@/views/Logistics/Transit/components/RequestTileList'
import utils from '@/helpers/transitUtils'

export default {
  name: 'BookedTransit',
  components: { RequestTileList, LocationBlock },
  props: {
    transit: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('transitDashboardStore', [
      // 'getTransitCheckIns',
      // 'getTransitManifests'
    ])
  },
  methods: {
    attachedRequests () {
      return utils.requestList(this.transit)
    }
  }
}
</script>
