var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('unauthorized',{attrs:{"authorized":_vm.$store.getters.can('create-transit'),"message":"Not Authorized to Create Transit"}},[_c('div',[_c('ValidationObserver',{ref:"validationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',[_c('v-col',{attrs:{"offset":"1","cols":"10","offset-md":"2","md":"8"}},[_c('base-title',[_vm._v(" Create Transit ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"offset":"1","cols":"10","offset-md":"2","md":"8"}},[_c('v-form',[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.transitType),callback:function ($$v) {_vm.transitType=$$v},expression:"transitType"}},[_c('v-radio',{attrs:{"label":"Inbound","value":"IN"}}),_c('v-radio',{attrs:{"label":"Outbound","value":"OUT"}})],1),_c('date-range-input',{attrs:{"start-date":_vm.pickupDate,"end-date":_vm.deliveryDate,"start-date-label":"Pick Up Date*","end-date-label":"Delivery Date*"},on:{"alterStartDate":function($event){_vm.pickupDate = $event},"alterEndDate":function($event){_vm.deliveryDate = $event}}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-location-field',{attrs:{"label":"Origin*","error-messages":errors},model:{value:(_vm.fromLocation),callback:function ($$v) {_vm.fromLocation=$$v},expression:"fromLocation"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-location-field',{attrs:{"label":"Destination*","error-messages":errors},model:{value:(_vm.toLocation),callback:function ($$v) {_vm.toLocation=$$v},expression:"toLocation"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[(_vm.fromLocation && _vm.toLocation)?_c('shipping-cost-history-table',{attrs:{"from-location":_vm.fromLocation,"to-location":_vm.toLocation}}):_c('div',[_c('em',{staticClass:"subtitle-2"},[_vm._v(" Historic Average displayed when origin an destination selected. ")])])],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"two_decimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-currency-usd","label":"Rate Quote","type":"number","error-messages":errors},model:{value:(_vm.rateQuote),callback:function ($$v) {_vm.rateQuote=$$v},expression:"rateQuote"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('create-broker-dialog',{on:{"created":_vm.setBroker},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-select',{attrs:{"label":"Broker","item-text":"name","items":_vm.brokerOptions,"clearable":"","return-object":"","append-outer-icon":"mdi-plus"},on:{"click:append-outer":on},model:{value:(_vm.broker),callback:function ($$v) {_vm.broker=$$v},expression:"broker"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('create-carrier-dialog',{attrs:{"minimal-fields":true},on:{"created":_vm.setCarrier},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"required":"","label":"Carrier*","item-text":"name","items":_vm.carrierOptions,"error-messages":errors,"clearable":"","return-object":"","append-outer-icon":"mdi-plus"},on:{"click:append-outer":on},model:{value:(_vm.carrier),callback:function ($$v) {_vm.carrier=$$v},expression:"carrier"}})]}}],null,true)})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-space-between"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":_vm.checkFieldsValid ? 'primary' : ''},on:{"click":_vm.create}},[_vm._v(" Create ")])],1)],1)],1)],1)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }