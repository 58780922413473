<!--
Document Reference List and Editor
Allows creating and deleting of a Django model's related References
-->
<template>
  <div>
    <slot
      name="activator"
      :on="() => dialog = !dialog"
    >
      <v-btn
        color="primary"
        small
        @click="dialog = !dialog"
      >
        Reference Editor
      </v-btn>
    </slot>
    <edit-dialog
      v-model="dialog"
      headline-text="Reference Editor"
      :show-decline="false"
      accept-text="Done"
    >
      <v-skeleton-loader
        v-if="fetching && !this.references.length"
        type="table-tbody"
      />
      <v-data-table
        :headers="dataTableHeaders"
        :items="references"
        hide-default-footer
        disable-pagination
        disable-sort
      >
        <!-- New reference -->
        <template v-slot:footer>
          <ValidationObserver
            ref="observer"
            v-slot="{ invalid }"
          >
            <v-card flat>
              <v-card-title class="subtitle-1">
                Add New Reference
              </v-card-title>
              <v-card-text>
                <v-form
                  @submit.prevent="submit"
                  ref="form"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      md="5"
                    >
                      <ValidationProvider
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-select
                          label="Reference Type"
                          v-model="ref_type"
                          :items="modelRefTypes"
                          :error-messages="errors"
                          item-text="name"
                          item-value="id"
                        />
                      </ValidationProvider>
                    </v-col>
                    <v-col
                      cols="12"
                      md="5"
                    >
                      <ValidationProvider
                        rules="required|max:55"
                        v-slot="{ errors }"
                        type="text"
                      >
                        <v-text-field
                          v-model="ref"
                          label="Reference ID"
                          :error-messages="errors"
                        />
                      </ValidationProvider>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                      :class="{ 'text-right': $vuetify.breakpoint.smAndDown }"
                    >
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="createReference"
                            :disabled="invalid"
                            small
                            color="primary"
                            title="Add new reference"
                          >
                            <v-icon dark>
                              mdi-plus
                            </v-icon>
                          </v-btn>
                        </template>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </ValidationObserver>
        </template>
        <!-- Delete button per table row -->
        <template v-slot:item.delete="{ item }">
          <v-btn
            icon
            @click="confirmDelete(item.id)"
          >
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <!-- Confirm deletion dialog -->
      <v-dialog
        v-model="dialogDelete"
        max-width="400px"
      >
        <v-card>
          <v-card-title class="text-h5">
            Confirm Deleting Reference
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="dialogDelete = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              class="red--text"
              @click="deleteReference"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </edit-dialog>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'DocumentReferenceEditor',
  props: {
    // The Django model name for entity whose references we're viewing/adding/deleting
    referenceModel: {
      type: String,
      required: true
    },
    // A Django model
    entityId: {
      type: Number,
      required: true
    }
  },
  watch: {
    dialog (opened) {
      if (opened) {
        this.fetchReferences()
      }
    },
    references (references) {
      this.$emit('update:references', references)
    }
  },
  data: () => ({
    dialog: false, // Dialog that encapsulates the entire component
    dialogDelete: false, // Delete reference dialog
    dataTableHeaders: [
      { text: 'Reference Type', value: 'ref_type_name' },
      { text: 'Reference ID', value: 'ref' },
      { text: 'Delete Reference', value: 'delete', align: 'center' }
    ],
    fetching: false,
    ref: null, // Reference ID the user may attempt to register
    ref_type: null, // Which type the user has selected while attempting to add a new reference
    references: [], // List of the entity's related reference
    refTypes: [], // List of available reference types for the Django model
    deletingReferenceId: null // Reference ID the user has selected for deletion in the v-data-table
  }),
  computed: {
    /**
     * Filter and return an array of refTypes by model name
     * @param refTypes
     * @returns {*}
     */
    modelRefTypes () {
      return this.refTypes.filter(type => type.reference_model === this.referenceModel)
    }
  },
  methods: {
    /**
     * Fetches Document Reference objects from the API by related model and model entity
     * @return {Promise<AxiosResponse<any>>}
     */
    async fetchReferences () {
      this.fetching = true
      this.references = []
      try {
        let params = {}
        params[this.referenceModel] = this.entityId
        let response = await axios.get('/api/document_references/', { params })
        this.references = response.data.results
      } catch {
        this.$store.commit('setSnackbarError', 'An error occurred while fetching references')
      }
      this.fetching = false
    },
    /**
     * Fetch the list of reference types relevant to a model
     * e.g. Transits have BOL, Truck/Trailer Number, SEAL, and PRO reference types
     * @returns {Promise<AxiosResponse<any>>}
     */
    async fetchReferenceTypes () {
      this.fetching = true
      this.refTypes = []
      try {
        const response = await axios.get('/api/document_references/ref_types/')
        this.refTypes = response.data
      } catch {
        this.$store.commit('setSnackbarError', 'An error occurred while attempting to fetch reference types.')
      }
      this.fetching = false
    },
    /**
     * Perform a POST request for a reference
     * Upon successful creation, push the reference to this.references array
     * Reset reference creation form
     * @returns {Promise<AxiosResponse<any>>}
     */
    async createReference () {
      this.fetching = true
      let requestBody = {
        ref: this.ref,
        ref_type: this.ref_type
      }
      // Ensure reference is applied to the correct model and id
      // e.g. { transit: 15 } translates to url '/transit/15'
      requestBody[this.referenceModel] = this.entityId
      try {
        const response = await axios.post('/api/document_references/', requestBody)
        this.references.push(response.data)
      } catch {
        this.$store.commit('setSnackbarError', 'An error occurred while attempting to update a reference.')
      }
      this.fetching = false
      this.$refs.observer.reset() // reset form validation
      this.$refs.form.reset() // reset form fields
    },
    /**
     * Set this.deletingReferenceId the id of the reference the user wishes to delete
     * Render confirm delete dialog
     * @param id
     */
    confirmDelete (id) {
      this.deletingReferenceId = id
      this.dialogDelete = true
    },
    /**
     * Perform a DELETE request by reference ID
     * @param id
     */
    async deleteReference () {
      try {
        await axios.delete(`/api/document_references/${this.deletingReferenceId}/`)
        this.references = this.references.filter((ref) => ref.id !== this.deletingReferenceId)
        this.dialogDelete = false // close dialog
      } catch {
        this.$store.commit('setSnackbarError', 'An error occurred while attempting to delete a reference.')
      }
    }
  },
  mounted () {
    // Fetch the global list of reference types
    this.fetchReferenceTypes()
  }
}
</script>
