var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (this.transit)?_c('div',[_c('h3',[_vm._v("Boxes")]),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-text',[_c('h4',[_vm._v(" "+_vm._s(_vm.attachedNames().join(' / '))+" ")]),_vm._v(" Expected Skids: "),_c('strong',[_vm._v(_vm._s(_vm.transitProperties.skids))]),_vm._v(" Weight: "),_c('strong',[_vm._v(_vm._s(_vm.transitProperties.weight)+" lbs")])])],1)],1)],1),(!_vm.readonly)?_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v(" Step 1: Enter Total Box Count ")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Box Count*","hint":"Total number of boxes arriving in transit.","rules":[function (x) { return !!x || 'This field is required'; }],"error-messages":errors},model:{value:(_vm.boxCount),callback:function ($$v) {_vm.boxCount=$$v},expression:"boxCount"}}),(_vm.rowsAndCountMatch)?_c('div',{staticClass:"red--text text--darken-3 v-size--x-small"},[_c('small',[_vm._v(" The number of boxes and this count do not match. ")])]):_vm._e()]}}],null,false,4054432989)})],1)],1)],1)],1)],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[(!_vm.readonly)?_c('span',[_vm._v("Step 2: ")]):_vm._e(),_vm._v(" Inventory Boxes "),_c('v-spacer')],1),(!_vm.readonly)?_c('ul',[_c('li',[_vm._v(" Boxes are automatically imported when loaded from Manifest and Check Ins (If they do not exist). "),_c('ul',[_c('li',[_vm._v(" Only imports declared skids from the check in. (not estimated) ")])])]),_c('li',[_vm._v(" The number of boxes must match the declared box count. ")]),_c('li',[_vm._v(" Boxes may be removed form this table using the "),_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-dots-vertical ")]),_vm._v(" icon. ")],1),_c('li',[_vm._v(" Boxes may be combined by first removing them from the table. Then selecting skids on the "),_c('v-btn',{attrs:{"x-small":""}},[_vm._v(" Add Box ")]),_vm._v(" dialog. ")],1),_c('li',[_vm._v(" All Skids (created at check in) must be accounted for. Do this by adding boxes with the attached skids. ")])]):_vm._e(),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers(),"items":_vm.boxRows,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.index + 1)+" ")]}},{key:"item.gpsNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.gpsNumber || '--')+" ")]}},(!_vm.readonly)?{key:"item.weight",fn:function(ref){
var item = ref.item;
return [_c('ValidationProvider',{attrs:{"rules":"required|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"max-width":"200px"},attrs:{"label":"Received lbs","type":"number","rules":errors},on:{"change":function($event){return _vm.updateWeight(item, $event)}},model:{value:(item.weight),callback:function ($$v) {_vm.$set(item, "weight", $$v)},expression:"item.weight"}})]}}],null,true)})]}}:null,{key:"item.menu",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.setMergeBox(item)}}},[_vm._v(" Combine Boxes... ")]),_c('v-list-item',{on:{"click":function($event){return _vm.removeBox(item)}}},[_vm._v(" Remove Box ")])],1)],1)],1)]}},{key:"body.append",fn:function(){return [(!_vm.$vuetify.breakpoint.xsOnly)?_c('tr',[_c('td'),_c('td',[_vm._v("Total Weight")]),_c('td'),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.totalReceivedWeight))+" lbs ")]),(!_vm.readonly)?_c('td'):_vm._e()]):_c('div',[_vm._v(" Total Weight "+_vm._s(_vm._f("formatNumber")(_vm.totalReceivedWeight))+" lbs ")])]},proxy:true}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),(!_vm.readonly)?_c('add-box-dialog'):_vm._e()],1)],1)],1)],1),(_vm.boxMergeDestination)?_c('box-row-select',{attrs:{"headline-text":'Merge Boxes into ' + _vm.boxMergeDestination.name,"boxes":_vm.boxRows.filter(function (x) { return _vm.boxMergeDestination ? x.index !== _vm.boxMergeDestination.index : true; })},on:{"boxesSelected":_vm.combineBoxes},model:{value:(_vm.selectBoxDialog),callback:function ($$v) {_vm.selectBoxDialog=$$v},expression:"selectBoxDialog"}}):_vm._e()],1):_c('div',[_c('h3',[_vm._v("Boxes")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-skeleton-loader',_vm._b({attrs:{"type":"heading, heading"}},'v-skeleton-loader',_vm.attrs,false)),_c('v-skeleton-loader',_vm._b({attrs:{"type":"table-heading, table-thead, table-row-divider@3, table-tfoot"}},'v-skeleton-loader',_vm.attrs,false))],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }