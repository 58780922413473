<template>
  <div>
    <v-card flat>
      <search-autocomplete
        label="Search Inventory Manifests by ID (w/o Transit)"
        api-path="/api/inventory_manifests/"
        :params="{ transit__isnull: true }"
        :format-entry="x => Object.assign({ friendlyName: `#${String(x.id)} ${x.remarks}` }, x)"
        @input="$emit('selected', $event)"
      />
    </v-card>
  </div>
</template>

<script>
import SearchAutocomplete from '@/base/components/SearchAutocomplete.vue'

export default {
  name: 'InventoryManifestSearchField',
  components: { SearchAutocomplete }
}
</script>
