<template>
  <edit-dialog
    :headline-text="headlineText"
    :value="value"
    :inputs-valid="boxesSelected.length > 0"
    @accept="selectBoxes"
    @input="$emit('input', $event)"
    accept-text="Done"
  >
    <v-data-table
      :headers="mergeHeaders"
      :items="boxes"
      @click:row="toggleSelected"
    >
      <template v-slot:item.index="{ item }">
        {{ item.index + 1 }}
      </template>
      <template v-slot:item.select="{ item }">
        <v-icon v-if="inboundIndexes.includes(item.index)">
          mdi-checkbox-marked-outline
        </v-icon>
        <v-icon v-else>
          mdi-checkbox-blank-outline
        </v-icon>
      </template>
    </v-data-table>
  </edit-dialog>
</template>

<script>
export default {
  name: 'BoxRowSelect',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    boxes: {
      type: Array,
      required: true
    },
    headlineText: {
      type: String,
      default: 'Select Boxes'
    }
  },
  data: () => ({
    boxesSelected: [],
    mergeHeaders: [
      { text: '', value: 'index' },
      { text: 'Ident', value: 'name' },
      { text: 'Weight', value: 'weight', align: 'right' },
      { text: 'Selected', value: 'select', align: 'right' }
    ]
  }),
  watch: {
    value (opened) {
      if (!opened) return
      this.boxesSelected = []
    }
  },
  computed: {
    /**
     * Returns a list of just the selected inbound indexes
     * @return {*[]}
     */
    inboundIndexes () {
      return this.boxesSelected.map(x => x.index)
    }
  },
  methods: {
    /**
     * Add / Remove selected transit box
     * @param box
     */
    toggleSelected (box) {
      if (this.inboundIndexes.includes(box.index)) {
        this.boxesSelected = this.boxesSelected.filter(x => x.index !== box.index)
      } else {
        this.boxesSelected.push(box)
      }
    },
    /**
     * Emits the selected Boxes
     */
    selectBoxes () {
      this.$emit('boxesSelected', this.boxesSelected)
      this.$emit('input', false)
    }
  }
}
</script>
