<template>
  <unauthorized
    :authorized="$store.getters.can('view-manifests')"
    message="Unauthorized to view Manifest"
  >
    <div v-if="entry">
      <url-breadcrumbs
        :items="[
          ['Manifest List', { name: 'manifest-list' }],
          ['Manifest #' + entry.id, { name: 'manifest-view', params: { id: entry.id }}]
        ]"
      />
      <v-container fluid>
        <base-title>
          Manifest #{{ entry.id }}
          <v-chip
            small
            :color="entry.in_transit ? 'green lighten-2' : ''"
          >
            {{ entry.in_transit ? 'In Transit' : 'Not In Transit' }}
          </v-chip>
          <template v-slot:subtitle>
            {{ entry.created_by__username }} on
            {{ entry.created_at | formatDate }}
          </template>
        </base-title>
        <v-chip
          v-if="entry.is_locked"
          small
        >
          locked
        </v-chip>
        <v-toolbar
          flat
          dense
        >
          <info-tooltip>
            <template v-slot:wrap-me>
              <v-btn
                :disabled="!!entry.transit || !$store.getters.can('delete-manifests')"
                small
                text
                color="accent"
                @click="deleteManifest"
              >
                Delete
              </v-btn>
            </template>
            may not be deleted if attached to a transit
          </info-tooltip>
          <v-spacer />
          <v-btn
            class="ml-2"
            small
            :to="{ name: 'manifest-print', params: { id: entry.id }}"
          >
            Print
          </v-btn>
          <user-upload-dialog
            class="ml-2"
            :file-links="fileLinks"
            @newFilesAdded="notifyNewFilesUploaded"
          />
          <v-btn
            class="ml-2"
            :disabled="!$store.getters.can('edit-manifests')"
            small
            color="primary"
            @click="editDialog = !editDialog"
          >
            Edit / Mark Shipped
          </v-btn>
        </v-toolbar>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title>
                Shipping
              </v-card-title>
              <v-card-text v-if="entry.in_transit">
                Seal Number:
                <span class="text-h5">
                  {{ entry.seal_number }}
                </span>
              </v-card-text>
              <v-card-text v-else>
                <h4>Before Pick Up</h4>
                <ul>
                  <li>
                    Add GPS trackers to boxes. (See Inventory Boxes Section.)
                  </li>
                </ul>
                <h4>After Pick Up</h4>
                <ul>
                  <li>
                    Upload Photos. See
                    <v-btn x-small>
                      Attached Transit Files
                    </v-btn>
                  </li>
                  <li>
                    Review Manifest
                  </li>
                  <li>
                    Enter Seal Number / Mark In Transit. See
                    <v-btn x-small>
                      Edit / Mark Shipped
                    </v-btn>
                  </li>
                </ul>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title>
                Remarks
              </v-card-title>
              <v-card-text
                class="red--text font-weight-bold"
              >
                {{ entry.remarks }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title>
                Transit
              </v-card-title>
              <v-card-text>
                Transit:
                <router-link-id
                  v-if="transit"
                  name="transit-view"
                  :id="transit.id"
                />
                <br>
                Transit Requested:
                {{ entry.transit_requested ? 'Yes' : 'No' }}
                <br>
                Requested Date:
                {{ entry.request_date | formatDate }}
                <v-row>
                  <v-col>
                    Location
                    <location-block
                      v-if="entry.current_location_details"
                      :location="entry.current_location_details"
                    />
                  </v-col>
                  <v-col>
                    Destination
                    <location-block
                      v-if="entry.to_location_details"
                      :location="entry.to_location_details"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-card>
          <v-card-title>
            Inventory Boxes <v-chip
              v-if="entry.lock_snapshot"
              class="ml-2"
              small
            >
              Locked
            </v-chip>
          </v-card-title>
          <v-card-text>
            <p>
              Inventory box weights and gps numbers may be added here by clicking on each row. Inventory may not be edited once in transit.
              <br>
              Only boxes in the same location may be added.
            </p>
            <v-data-table
              v-if="!entry.lock_snapshot"
              :headers="headers"
              :items="boxRows"
              :items-per-page="-1"
              @click:row="setInventoryToEdit($event)"
            >
              <template v-slot:item.index="{ item }">
                {{ item.index + 1 }}
              </template>
              <template v-slot:item.name="{ item }">
                <router-link-id
                  name="inventory-on-hand-box"
                  :id="item.source_id"
                >
                  <span class="text-no-wrap">
                    {{ item.name }}
                  </span>
                </router-link-id>
                <div>
                  <div class="grey--text">
                    {{ item.source_id }}
                  </div>
                </div>
              </template>
              <template v-slot:item.gpsNumber="{ item }">
                {{ item.gpsNumber || '--' }}
              </template>
              <template v-slot:item.contains="{ item }">
                <clickable-contains :contains="item.contains" />
              </template>
              <template v-slot:item.weight="{ item }">
                {{ item.weight | formatNumber }} lbs
              </template>
              <template v-slot:body.append="{ header, item }">
                <tr>
                  <td
                    class="font-weight-bold"
                    :colspan="4"
                  >
                    Total
                  </td>
                  <td class="font-weight-bold text-right">
                    {{ totalWeight | formatNumber }} lbs
                  </td>
                </tr>
              </template>
            </v-data-table>
            <boxes-snapshot
              v-else
              :snapshot="entry.inventory_box_snapshot"
            />
          </v-card-text>
          <manifest-lines-edit-dialog
            :manifest="entry"
            :boxes="inventoryBoxes"
            @updated="fetchInventoryBoxes"
          >
            <template v-slot:activator="{ toggle }">
              <v-btn
                v-if="!entry.is_locked"
                fab
                bottom
                right
                absolute
                color="primary"
                @click="toggle"
              >
                <v-icon>
                  mdi-playlist-edit
                </v-icon>
              </v-btn>
            </template>
          </manifest-lines-edit-dialog>
        </v-card>
      </v-container>
    </div>
    <ValidationObserver
      v-if="entry"
      v-slot="{ invalid }"
    >
      <edit-dialog
        v-if="inventoryToEdit"
        :headline-text="'Update Box ' + inventoryToEdit.name"
        v-model="inventoryEditDialog"
        :inputs-valid="!invalid"
        @accept="updateInventoryBox"
      >
        <ValidationProvider
          rules="required|min_value:0|two_decimal"
          v-slot="{ errors }"
        >
          <v-text-field
            label="Weight"
            type="number"
            v-model="weight"
            :error-messages="errors"
          />
        </ValidationProvider>
        <v-text-field
          label="GPS Number"
          v-model="gps"
        />
      </edit-dialog>
      <edit-dialog
        headline-text="Edit / Mark Shipped"
        v-model="editDialog"
        @accept="updateManifest"
      >
        <v-card
          class="mb-4"
          outlined
        >
          <v-card-title>
            General
          </v-card-title>
          <v-card-text>
            <v-textarea
              label="Remarks"
              v-model="remarks"
            />
            <info-tooltip>
              <template v-slot:wrap-me>
                <v-checkbox
                  :disabled="!!entry.transit"
                  label="Requires Transit"
                  v-model="requiresTransit"
                />
              </template>
              only allowed if there is no transit
            </info-tooltip>
          </v-card-text>
        </v-card>
        <v-card outlined>
          <v-card-title>
            Shipping
          </v-card-title>
          <v-card-text>
            <v-text-field
              :disabled="entry.is_locked"
              label="Seal Number"
              v-model="sealNumber"
              hint="Seal Number is required to mark a manifest shipped."
            />
            <info-tooltip>
              <template v-slot:wrap-me>
                <v-checkbox
                  :disabled="entry.is_locked || (!entry.transit && sealNumber === '')"
                  label="Mark Shipped"
                  v-model="inTransit"
                />
              </template>
              only available when transit connected & seal number required
            </info-tooltip>
          </v-card-text>
        </v-card>
      </edit-dialog>
    </ValidationObserver>
  </unauthorized>
</template>

<script>
import LocationBlock from '@/views/Logistics/Transit/components/LocationBlock'
import axios from 'axios'
import BoxesSnapshot from '@/views/Manifest/components/BoxesSnapshot'
import ClickableContains from '@/views/Manifest/components/ClickableContains'
import UserUploadDialog from '@/base/components/UserUploadDialog'
import fileLinks from '@/helpers/fileLinks'
import ManifestLinesEditDialog from './components/ManifestLinesEditDialog.vue'

export default {
  name: 'ManifestView',
  components: { ManifestLinesEditDialog, UserUploadDialog, ClickableContains, BoxesSnapshot, LocationBlock },
  data: () => ({
    requiresTransit: false,
    loading: false,
    inTransit: false,
    transit: null,
    inventoryToEdit: null,
    inventoryEditDialog: false,
    inventoryLinesEditDialog: false,
    weight: 0,
    gps: '',

    sealNumber: '',
    remarks: '',

    editDialog: false,
    inventoryBoxes: [],

    headers: [
      { text: '', value: 'index' },
      { text: 'Ident', value: 'name' },
      { text: 'Contains', value: 'contains' },
      { text: 'Weight', value: 'weight', align: 'right' },
      { text: 'GPS', value: 'gpsNumber' }
    ]
  }),
  watch: {
    entry (manifest) {
      this.sealNumber = manifest.seal_number
      this.remarks = manifest.remarks
      this.requiresTransit = manifest.transit_requested
      this.inTransit = manifest.in_transit
    },
    inventoryToEdit (box) {
      this.weight = box.weight
      this.gps = box.gps_number
    }
  },
  computed: {
    /**
     * Puts together the related file links
     */
    fileLinks () {
      return fileLinks.manifestTransitFileLinks(this.entry, this.transit)
    },
    totalWeight () {
      return this.inventoryBoxes.reduce((t, b) => t + Number(b.weight), 0)
    },
    entry () {
      return this.$store.state['inventoryManifestStore'].items[this.$route.params.id]
    },
    boxRows () {
      return this.inventoryBoxes.map((box, index) => {
        return {
          index,
          name: box.name,
          source_id: box.source_id,
          contains: box.contains,
          gpsNumber: box.gps_number,
          weight: box.weight,
          meta: box
        }
      })
    }
  },
  methods: {
    // triggers manifest endpoint to notify about new files uploaded
    notifyNewFilesUploaded () {
      axios.post(this.entry.url + 'notify_files_uploaded/').then(() => {
        this.$store.commit('setSnackbarInfo', 'File uploaded notification sent')
      })
    },
    updateManifest () {
      if (this.inTransit && this.sealNumber === '') {
        this.$store.commit('setSnackbarWarning', 'Seal Number is required when marking In Transit')
        return
      }
      const payload = {
        seal_number: this.sealNumber,
        remarks: this.remarks,
        transit_requested: this.requiresTransit,
        in_transit: this.inTransit
      }
      this.$store.dispatch('inventoryManifestStore/patchInventoryManifest', {
        id: this.entry.id,
        payload
      })
    },
    updateInventoryBox () {
      const id = this.inventoryToEdit.id
      const payload = {
        weight: this.weight,
        gps_number: this.gps
      }
      this.$store.dispatch('inventoryBoxStore/patchInventoryBox', { id, payload }).then(res => {
        if (res.status === 200) {
          const box = res.data
          this.inventoryBoxes = this.inventoryBoxes.map(ib => ib.url === box.url ? box : ib)
        }
      })
    },
    setInventoryToEdit (box) {
      if (!this.$store.getters.can('edit-inventory-boxes')) {
        this.$store.commit('setSnackbarWarning', 'Unauthorized to update box')
        return
      }
      if (this.entry.in_transit) {
        return
      }
      this.inventoryToEdit = box
      this.inventoryEditDialog = true
    },
    async fetchInventoryBoxes () {
      if (!this.entry) {
        this.$store.commit('setSnackbarWarning', 'Tried to load inventory boxes without a Manifest.')
        return
      }
      const params = { manifest: this.entry.id, page_size: 0, status__in: ['AC', 'ST', 'CU', 'SH'].join(',') }
      const res = await axios.get('/api/inventory_boxes/', { params })
      if (res.status !== 200) {
        this.$store.commit('setSnackbarWarning', 'Unable to retrieve inventory boxes.')
        return
      }
      this.inventoryBoxes = res.data
    },
    deleteManifest () {
      if (confirm('Are you sure you would like to delete this transit?')) {
        const id = this.entry.id
        this.$store.dispatch('inventoryManifestStore/deleteInventoryManifest', id).then(r => {
          if (r.status === 204) {
            this.$store.commit('setSnackbarInfo', `Manifest ${id} has been deleted.`)
            this.$router.push({ name: 'manifest-list' })
          }
        })
      }
    },
    /**
     * Fetches the manifest transit object if it exists
     * @return {Promise<void>}
     */
    async fetchTransit () {
      if (!this.entry) {
        this.$store.commit('setSnackbarWarning', 'Tried to load Transit without a Manifest.')
        return
      }
      if (!this.entry.transit) return
      const res = await axios.get(this.entry.transit)
      if (res.status === 200) this.transit = res.data
    }
  },
  async mounted () {
    await this.$store.dispatch('inventoryManifestStore/fetchInventoryManifest', this.$route.params.id)
    await this.fetchInventoryBoxes()
    await this.fetchTransit()
  }
}
</script>
