<template>
  <div>
    <v-list>
      <v-list-item
        v-for="(manifest, index) in lotManifests"
        :key="index"
        @click="$emit('clicked', manifest)"
      >
        <v-list-item-content>
          <v-list-item-title>
            #{{ manifest.id }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ manifest.lots__details.map(l => l.name).join(', ') }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon v-if="appendIcon">
            {{ appendIcon }}
          </v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  name: 'LotManifestDetailList',
  props: {
    lotManifests: {
      type: Array,
      required: true
    },
    appendIcon: {
      type: String,
      default: null
    }
  }
}
</script>
