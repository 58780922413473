<template>
  <div>
    <v-toolbar
      flat
      dense
    >
      <user-upload-dialog
        :file-links="fileLinks"
      />
      <v-spacer />
      <v-btn
        v-if="!$vuetify.breakpoint.smAndDown"
        :disabled="disableReceiveTransit"
        color="primary"
        small
        @click="receiveTransit"
      >
        Receive Transit
      </v-btn>
      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mx-2"
            small
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>
              mdi-dots-vertical
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-if="$vuetify.breakpoint.smAndDown"
            :disabled="disableReceiveTransit"
            @click="receiveTransit"
          >
            Receive Transit
          </v-list-item>
          <v-list-item
            @click="transferDialog = true"
          >
            Houston Manifest Transfer
          </v-list-item>
          <v-list-item
            v-if="$store.getters.can('delete-transit')"
            @click="deleteTransit"
          >
            <info-tooltip v-if="!$vuetify.breakpoint.smAndDown">
              <template v-slot:wrap-me>
                Delete
              </template>
              requests will be moved back to requested
            </info-tooltip>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <receive-transit-dialog
      v-model="dialog"
    />
    <edit-dialog
      headline-text="Begin Receiving Process"
      v-model="confirmReceiveDialog"
      accept-text="confirm"
      @accept="changeTransitStatusToReceiving"
    >
      <strong>
        Please confirm you would like to begin receiving this transit.
        Once receiving has started Check Ins and Manifests may not be changed.
      </strong>
    </edit-dialog>
    <edit-dialog
      headline-text="Houston Manifest Transfer"
      v-model="transferDialog"
      accept-text="Confirm"
      :inputs-valid="allowTransfer === ''"
      @accept="processTransfer"
    >
      <p>
        <strong>
          Requires: Houston Destination, Only one Manifest
        </strong>
      </p>
      <p>
        Using this feature will automatically <strong>complete this transit</strong> then
        <strong>create a duplicate transit and manifest</strong>.
        These duplicate entries will be based from Houston with a destination of Phoenix. Once duplicated the manifest
        may be modified so that inventory boxes may be added or removed before being received in Phoenix.
      </p>
      <p
        v-if="allowTransfer"
        class="red--text text-center"
      >
        <strong>
          {{ allowTransfer }}
        </strong>
      </p>
    </edit-dialog>
  </div>
</template>

<script>
import ReceiveTransitDialog from '@/views/Logistics/Transit/components/ReceiveTransitDialog'
import UserUploadDialog from '@/base/components/UserUploadDialog'
import fileLinks from '@/helpers/fileLinks'

export default {
  name: 'EditTransitToolbar',
  components: { UserUploadDialog, ReceiveTransitDialog },
  data: () => ({
    dialog: false,
    transferDialog: false,
    confirmReceiveDialog: false
  }),
  computed: {
    allowTransfer () {
      if (!this.$store.getters.can('transfer-manifest')) return 'You do not have permission to preform a transfer.'
      if (this.transit.to_address_details?.name !== 'Houston') return 'Must have destination Houston.'
      if (this.transit.manifests.length !== 1) return 'Transfers are only allowed when there is one Manifest attached.'
      return ''
    },
    fileLinks () {
      return fileLinks.transitFileLink(this.transit)
    },
    transit () {
      return this.$store.getters['transitDashboardStore/editTransit/transit']
    },
    status () {
      return this.transit.status
    },
    disableReceiveTransit () {
      return !(this.$store.getters.can('receive-transit') || this.$store.getters.can('resolve-transit'))
    }
  },
  methods: {
    /**
     * Automate the creation of a new manifest by first closing this transit
     * and manifest and duplicating them
     */
    processTransfer () {
      this.$store.dispatch('transitDashboardStore/autoTransferManifest', this.transit).then(r => {
        if (r.data.new_transit) {
          const info = `Transit #${r.data.new_transit.id} & Manifest #${r.data.new_manifest.id} created`
          this.$store.commit('setSnackbarInfo', info)
        }
      })
    },
    deleteTransit () {
      if (confirm('Are you sure you want to permanently delete this transit?')) {
        this.$store.dispatch('transitDashboardStore/editTransit/deleteTransit')
      }
    },
    receiveTransit () {
      if (this.status === 'RE') {
        this.dialog = true
      } else {
        this.confirmReceiveDialog = true
      }
    },
    changeTransitStatusToReceiving () {
      this.$store.dispatch('transitDashboardStore/editTransit/patchTransit', { status: 'RE' }).then(() => {
        if (this.transit.status === 'RE') {
          this.dialog = true
        } else {
          this.$store.commit('setSnackbarWarning', 'Unable to change transit status to Receiving')
        }
      })
    }
  }
}
</script>
