<template>
  <ValidationObserver
    v-slot="{ invalid }"
  >
    <edit-dialog
      :value="value"
      headline-text="Receive this Transit"
      accept-text="Confirm"
      @input="$emit('input', $event)"
      :inputs-valid="!invalid && boxesValid && noUnassignedSkids"
      @accept="dispatchReceiveTransit"
      :max-width="1000"
    >
      <received-boxes
        :key="transit.id"
        v-model="boxesValid"
      />
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              Step 3: Attach Files
            </v-card-title>
            <v-card-text>
              Before continuing it is strongly recommend that you attach all necessary files here.
              Including Images/BOL and any other shipment related file.
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <user-upload-dialog
                :file-links="{ transits: transit.id }"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="primary"
                    small
                    @click="on"
                  >
                    Click Here to Upload Files/Images
                  </v-btn>
                </template>
              </user-upload-dialog>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              Step 4: Additional Details
            </v-card-title>
            <v-card-text>
              <ValidationProvider
                rules="required"
                v-slot="{ errors }"
              >
                <date-input
                  label="Received Date*"
                  v-model="receivedDate"
                  :max="getCurrentDate()"
                  @change="updateReceivedDate"
                />
              </ValidationProvider>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              Step 5: Disputes
            </v-card-title>
            <v-card-text>
              <v-text-field
                v-model="disputeNotes"
                label="Dispute Reason"
              />
              <p
                v-if="!skidCountsMatch"
                class="red--text text-center font-italic"
              >
                The expected skid counts do not match the box count. Please explain.
              </p>
              <p
                v-if="!withinAllowedWeightVariance"
                class="red--text text-center font-italic"
              >
                The total skid weight is outside of the allowed variance based on the expected weight. Please Explain.
              </p>
              <p class="red--text text-center font-italic">
                Please include any remarks regarding the state of the skids such as damage or alterations.
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="text-center">
        <v-col>
          <p
            class="red--text font-italic"
          >
            By Clicking CONFIRM you are agreeing that all of the listed check ins have been properly accounted for.
          </p>
        </v-col>
      </v-row>
    </edit-dialog>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from 'vuex'
import ReceivedBoxes from '@/views/Logistics/Transit/components/ReceivedBoxes'
import UserUploadDialog from '@/base/components/UserUploadDialog'

function getCurrentDate () {
  const date = new Date()
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}
export default {
  name: 'ReceiveTransitDialog',
  components: { UserUploadDialog, ReceivedBoxes },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    boxesValid: true,
    receivedDate: getCurrentDate(),
    disputeNotes: ''
  }),
  computed: {
    checkIns () {
      return this.$store.getters['transitDashboardStore/editTransit/checkIns']
    },
    noUnassignedSkids () {
      return this.$store.getters['transitDashboardStore/editTransit/unassignedSkids'].length === 0
    },
    ...mapGetters('transitDashboardStore/editTransit', [
      'skidCountsMatch',
      'withinAllowedWeightVariance'
    ]),
    transit () {
      return this.$store.getters['transitDashboardStore/editTransit/transit']
    }
  },
  watch: {
    value (opened) {
      if (opened) {
        Promise.all([
          this.$store.dispatch('transitDashboardStore/editTransit/fetchBoxes')
        ])
        this.disputeNotes = this.transit.dispute_notes
      }
    }
  },
  methods: {
    getCurrentDate,
    updateTransitMeta (id) {
      const payload = {
        received_date: this.receivedDate,
        dispute_notes: this.disputeNotes
      }
      return this.$store.dispatch('transitStore/patchTransit', { id, payload }).then(res => {
        if (res.status === 200) {
          this.$store.commit('setSnackbarInfo', 'Dispute notes updated.')
        }
      })
    },
    /**
     * Ability to close out a transit entry as received
     */
    async dispatchReceiveTransit () {
      const id = this.transit.id
      await this.updateTransitMeta(id)
      await this.$store.dispatch('transitDashboardStore/editTransit/receiveTransit')
      await this.$store.dispatch('transitStore/dispatchTransitEmail', id)
    }
  }
}
</script>
