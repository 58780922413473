import axios from 'axios'

export function createInventoryManifest (params) {
  if (!params.hasOwnProperty('boxes')) params.boxes = []
  return axios.post(`/api/inventory_manifests/`, params)
}

export function patchInventoryManifest (id, params) {
  return axios.patch(`/api/inventory_manifests/${id}/`, params)
}
