<template>
  <unauthorized
    :authorized="$store.getters.can('view-transit')"
    message="Unauthorized to view Transit"
  >
    <app-loading-indicator
      v-if="loading"
    />
    <div v-else-if="entry">
      <url-breadcrumbs
        :items="[
          ['Transit List', { name: 'transit-list' }],
          ['Transit #' + entry.id, { name: 'transit-view', params: { id: entry.id }}]
        ]"
      />
      <v-container fluid>
        <base-title>
          Inbound Transit #{{ entry.id }}
          <v-chip
            small
            color="primary"
          >
            {{ entry.status_display.toUpperCase() }}
          </v-chip>
        </base-title>
        <v-toolbar
          flat
          dense
        >
          <user-upload-dialog
            :file-links="fileLinks"
          />
          <v-spacer />
          <v-btn
            small
            text
            @click="sendEmail"
          >
            Send Transit Email
          </v-btn>
          <v-btn
            :disabled="!$store.getters.can('edit-transit')"
            small
            color="primary"
            @click="editDialog = !editDialog"
          >
            Edit
          </v-btn>
        </v-toolbar>
        <h2>
          Details
        </h2>
        <v-row>
          <v-col>
            <v-card>
              <v-card-text>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <strong>
                          Shipping Company Broker/Carrier
                        </strong>
                      </v-list-item-title>
                      <router-link-id
                        v-if="entry.broker_data"
                        name="broker-view"
                        :id="entry.broker_data.id"
                      >
                        {{ entry.broker_data.name }}
                      </router-link-id>
                      <router-link-id
                        v-if="entry.carrier_data"
                        name="carrier-view"
                        :id="entry.carrier_data.id"
                      >
                        {{ entry.carrier_data.name }}
                      </router-link-id>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <strong>
                          Quoted Rate
                        </strong>
                      </v-list-item-title>
                      {{ entry.rate_quote | currencyUSD }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <strong>
                          Actual Cost
                        </strong>
                      </v-list-item-title>
                      <fx-tag
                        :fx="entry.actual_cost"
                        :value="entry.actual_cost"
                        :cur="entry.actual_cost_denomination"
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <strong>
                          Additional Fees
                        </strong>
                      </v-list-item-title>
                      <fx-tag
                        :fx="entry.additional_fees_fx"
                        :value="entry.additional_fees"
                        :cur="entry.additional_fees_denomination"
                      />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <strong>
                          Lot Allocations <v-btn
                            text
                            small
                            @click="editTransitLots = !editTransitLots"
                          >{{ editTransitLots ? 'done' : 'edit' }}</v-btn>
                        </strong>
                      </v-list-item-title>
                      <transit-lot-edit
                        v-if="editTransitLots"
                        :transit="entry"
                        @change="fetchTransit"
                      />
                      <v-data-table
                        v-else
                        :items="entry.transitlot_set"
                        :headers="[
                          { text: 'Lot', value: 'lot_name' },
                          { text: 'Weight', value: 'weight', align: 'right' },
                          { text: 'Percent', value: 'allocation_percent', align: 'right' },
                          { text: 'Value', value: 'allocation_value', align: 'right' }
                        ]"
                      >
                        <template v-slot:item.weight="{ item }">
                          {{ item.weight | formatNumber }} lbs
                        </template>
                        <template v-slot:item.allocation_percent="{ item }">
                          {{ item.allocation_percent * 100 }}%
                        </template>
                        <template v-slot:item.allocation_value="{ item }">
                          ${{ item.allocation_value | formatNumber }}
                        </template>
                      </v-data-table>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <strong>
                          Fee/Rate Adjustment Notes
                        </strong>
                      </v-list-item-title>
                      {{ entry.fee_description || 'None' }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <strong>
                          Received Date
                        </strong>
                      </v-list-item-title>
                      <span v-if="entry.received_date">
                        {{ entry.received_date | formatDate }}
                      </span>
                      <span v-else>
                        None
                      </span>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <strong>
                          Dispute notes
                        </strong>
                      </v-list-item-title>
                      {{ entry.dispute_notes || 'None' }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="entry.notes"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        <strong>
                          Notes
                        </strong>
                      </v-list-item-title>
                      {{ entry.notes }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <h3>
          Locations
        </h3>
        <v-row>
          <v-col>
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="6"
                  >
                    <h3>
                      Delivery From
                    </h3>
                    {{ entry.pickup_date | formatDate }}<em>Booked</em>
                    <location-block
                      class="mt-4"
                      :location="entry.from_address_details"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                  >
                    <h3>
                      Delivery To
                    </h3>
                    <span v-if="!entry.received_at">
                      {{ entry.delivery_date | formatDate }}<em>Estimated</em>
                    </span>
                    <span>
                      {{ entry.received_at | formatDate }}<em>Recieved</em>
                    </span>
                    <location-block
                      class="mt-4"
                      :location="entry.to_address_details"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <h3>
          Attached Requests
        </h3>
        <request-tile-list
          :request-list="attachedRequests"
        />
        <received-boxes
          readonly
        />
      </v-container>
      <ValidationObserver
        v-slot="{ invalid }"
      >
        <edit-dialog
          v-model="editDialog"
          headline-text="Edit Transit"
          :inputs-valid="!invalid"
          @accept="updateTransit"
        >
          <v-card-text>
            <v-select
              required
              label="Carrier*"
              v-model="carrier"
              item-text="name"
              :items="carrierOptions"
              clearable
              return-object
            />
            <v-select
              label="Broker*"
              v-model="broker"
              item-text="name"
              :items="brokerOptions"
              return-object
              clearable
              required
            />
            <ValidationProvider
              rules="two_decimal"
              v-slot="{ errors }"
            >
              <v-text-field
                type="number"
                label="Rate Quote"
                :error-messages="errors"
                v-model="rateQuote"
              />
            </ValidationProvider>
            <ValidationProvider
              rules="two_decimal"
              v-slot="{ errors }"
              v-if="entry.status === 'CO'"
            >
              <v-text-field
                type="number"
                label="Actual Cost"
                :error-messages="errors"
                v-model="actualCost"
              />
            </ValidationProvider>
            <ValidationProvider
              rules="max:255"
              v-slot="{ errors }"
            >
              <v-text-field
                label="Notes"
                v-model="notes"
                :error-messages="errors"
              />
              <p class="text-center red--text font-italic">
                These notes only appear on the Transit View
              </p>
            </ValidationProvider>
          </v-card-text>
        </edit-dialog>
      </ValidationObserver>
    </div>
    <div v-else>
      No Data
    </div>
  </unauthorized>
</template>

<script>
import LocationBlock from '@/views/Logistics/Transit/components/LocationBlock'
import ReceivedBoxes from '@/views/Logistics/Transit/components/ReceivedBoxes'
import RequestTileList from '@/views/Logistics/Transit/components/RequestTileList'
import UserUploadDialog from '@/base/components/UserUploadDialog'
import utils from '@/helpers/transitUtils'
import fileLinks from '@/helpers/fileLinks'
import { fetchAllBrokers, fetchAllCarriers } from '@/requests/logistics'
import TransitLotEdit from '@/views/Logistics/Transit/components/TransitLotEdit.vue'

export default {
  name: 'TransitInbound',
  components: { TransitLotEdit, RequestTileList, ReceivedBoxes, LocationBlock, UserUploadDialog },
  data: () => ({
    editTransitLots: false,
    loading: true,
    editDialog: false,
    notes: '',
    rateQuote: 0,
    actualCost: null,
    checkIns: [],
    carrier: {},
    broker: {},
    brokerOptions: [],
    carrierOptions: []
  }),
  watch: {
    editDialog (opened) {
      if (!opened) return
      this.notes = this.entry.notes
      this.rateQuote = this.entry.rate_quote
      this.broker = this.entry.broker_data
      this.carrier = this.entry.carrier_data
    }
  },
  computed: {
    attachedRequests () {
      return utils.requestList(this.entry)
    },
    entry () {
      return this.$store.state['transitStore'].items[this.$route.params.id]
    },
    fileLinks () {
      return fileLinks.transitFileLink(this.entry)
    }
  },
  methods: {
    sendEmail () {
      if (confirm('Are you sure you want to send an update to the freight group?')) {
        this.$store.dispatch('transitStore/dispatchTransitEmail', this.$route.params.id)
      }
    },
    updateTransit () {
      let payload = {
        notes: this.notes,
        broker: this.broker ? this.broker.url : null,
        carrier: this.carrier ? this.carrier.url : null,
        rate_quote: this.rateQuote
      }
      if (this.entry.status === 'CO' && this.actualCost !== null) {
        payload.actual_cost = this.actualCost
      }
      this.$store.dispatch('transitStore/patchTransit', { id: this.$route.params.id, payload })
    },
    fetchTransit () {
      return this.$store.dispatch('transitStore/fetchTransit', this.$route.params.id)
    }
  },
  async mounted () {
    await this.fetchTransit()
    this.$store.commit('transitDashboardStore/editTransit/setTransit', this.entry)
    fetchAllBrokers().then(r => {
      if (r.status === 200) this.brokerOptions = r.data
    })
    fetchAllCarriers().then(r => {
      if (r.status === 200) this.carrierOptions = r.data
    })
    this.$store.dispatch('transitDashboardStore/editTransit/fetchBoxes')
    this.loading = false
  }
}
</script>
