<template>
  <v-data-table
    :headers="headers"
    :items="inventoryBoxes"
    :items-per-page="-1"
    disable-pagination
    hide-default-footer
    dense
  >
    <template v-slot:item.name="{ item }">
      <strong class="text-no-wrap">
        {{ item.rowId }}. {{ item.name }}
      </strong>
      <div v-if="item.gps_number">
        <em>{{ item.gps_number }}</em>
      </div>
    </template>
    <template v-slot:item.contains="{ item }">
      <clickable-contains :contains="item.contains" />
      <span v-if="item.in_lots">
        <div
          class="text-no-wrap"
          v-for="lot in item.in_lots"
          :key="lot"
        >
          {{ lot }}
        </div>
        <div class="text-right">
          <small>
            {{ item.remarks }}
          </small>
        </div>
      </span>
    </template>
    <template v-slot:item.initial_weight="{ item }">
      <span class="text-no-wrap">
        {{ item.initial_weight | formatNumber }}&nbsp;lbs
      </span>
    </template>
    <template v-slot:item.final_weight="{ item }">
      <span class="text-no-wrap">
        {{ item.final_weight | formatNumber }}&nbsp;lbs
      </span>
    </template>
    <template v-slot:body.append="{ header, item }">
      <tr>
        <td
          class="font-weight-bold"
          :colspan="2"
        >
          Total Weight
        </td>
        <td class="font-weight-bold text-right text-no-wrap">
          {{ totalInitialWeight | formatNumber }}&nbsp;lbs
        </td>
        <td class="font-weight-bold text-right text-no-wrap">
          {{ totalFinalWeight | formatNumber }}&nbsp;lbs
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>

import ClickableContains from '@/views/Manifest/components/ClickableContains'
export default {
  name: 'BoxesSnapshot',
  components: { ClickableContains },
  props: {
    snapshot: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    headers: [
      { text: 'Ident', value: 'name', divider: true },
      {
        text: 'Vendors - ID / Lots / Notes',
        value: 'contains',
        divider: true,
        // The user can sort by the first Check-In's yard name instead of by the Check-In's ID
        sort: (a, b) => {
          // 58922 (Enovya International)
          // return anything between parentheses e.g. "Enovya International"
          let regex = /\((.*)\)/
          let aName = a.length ? a[0].match(regex)[0] : ''
          let bName = b.length ? b[0].match(regex)[0] : ''
          return aName.localeCompare(bName)
        }
      },
      // lot?
      { text: 'Weight', value: 'initial_weight', align: 'right', divider: true },
      { text: 'Received Weight', value: 'final_weight', align: 'right', divider: true }
      // { text: 'Notes', value: 'remarks', divider: true, width: 300 }
    ]
  }),
  computed: {
    inventoryBoxes () {
      let boxes = []
      let rowId = 1
      for (const key in this.snapshot) {
        const ss = this.snapshot[key]
        ss.rowId = rowId++
        boxes.push(ss)
      }
      return boxes
    },
    totalInitialWeight () {
      return this.inventoryBoxes.reduce((t, b) => t + Number(b.initial_weight), 0)
    },
    totalFinalWeight () {
      return this.inventoryBoxes.reduce((t, b) => t + Number(b.final_weight), 0)
    }
  }
}
</script>
