<!--
Shows all freight requests
Child of TransitDashboard
-->
<template>
  <div>
    <h4>
      Freight Requested
      <info-tooltip>
        Shows all Requests (Manifest / Check Ins).
      </info-tooltip>
    </h4>
    <v-sheet
      style="max-height: 75vh"
      class="overflow-y-auto pr-2"
    >
      <request-tile-list
        :request-list="$store.getters['transitDashboardStore/requests/getRequested']"
        @click="editRequest"
      />
    </v-sheet>
    <edit-request v-model="show" />
  </div>
</template>

<script>
import EditRequest from '@/views/Logistics/Transit/components/EditRequest'
import RequestTileList from '@/views/Logistics/Transit/components/RequestTileList'
export default {
  name: 'FreightRequests',
  components: { RequestTileList, EditRequest },
  data: () => ({
    show: false
  }),
  methods: {
    // Ses the edit request dialog to true and updates request to edit in the store
    editRequest (request) {
      this.$store.commit('transitDashboardStore/requests/setRequestToEdit', request)
      this.show = true
    }
  }
}
</script>
